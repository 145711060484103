import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { post } from "../../utils/GlobalUtils";
import { toast } from "sonner";
import { useAuth } from "../AuthContext/AuthContext";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await post("/users/login", {
        email: email,
        password: password,
      });
      if (response.status === 200) {
        login(response.data.role);

        if (response.data.role === "admin") {
          navigate("/Admin/products", { replace: true });
        } else {
          navigate("/", { replace: true });
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        console.error("Invalid email or password");
        toast.error("Invalid email or password", {
          position: "top-center",
          duration: 3000,
          richColors: true,
        });
      } else {
        toast.error("Unknow error, please try again", {
          position: "top-center",
          duration: 3000,
          richColors: true,
        });
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <div className="form-container">
        <p className="title">Welcome back</p>
        <form className="form">
          <input
            type="email"
            className="input"
            placeholder="Email"
            onChange={handleEmailChange}
            value={email}
          />
          <input
            type="password"
            className="input"
            placeholder="Password"
            onChange={handlePasswordChange}
            value={password}
          />
          <button className="form-btn" onClick={handleSubmit}>
            Log in
          </button>
          <div className="bottom-links">
            <p className="page-link">
              <span className="page-link-label">Forgot Password?</span>
            </p>
            <Link to="/register" className="sign-up-label">
              Don't have an account? Sign up
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
