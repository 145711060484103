import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row, Form, Button, Spinner } from "react-bootstrap";
import { updateProduct, getProductByid } from "../../../utils/ProductUtil";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./ProductImagePreview.css";
import { Navigation } from "swiper/modules";
import { website, webApi } from "../../../utils/GlobalUtils";
import { useNavigate } from "react-router-dom";

function UpdateProduct() {
  const navigate = useNavigate();
  const [editproductData, setEditproductData] = useState({});
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: 0,
    category_ids: [],
  });
  const [files, setFiles] = useState({ main_image: null, images: [] });
  const [deletedImages, setDeletedImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState({
    main_image: null,
    images: [],
  });
  const [countImages, setCountImages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hierarchy, setHierarchy] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const mainImageRef = useRef(null);
  const additionalImagesRef = useRef(null);

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    const filteredFiles = Array.from(files).filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return ["jpg", "png", "jpeg"].includes(extension);
    });

    if (filteredFiles.length === 0) {
      console.log("No valid files selected.");
      return;
    }

    if (name === "main_image") {
      const file = filteredFiles[0];
      setFiles((prevFiles) => ({
        ...prevFiles,
        main_image: file,
      }));
      setImagePreviews((prevPreviews) => ({
        ...prevPreviews,
        main_image: URL.createObjectURL(file),
      }));
      setCountImages(1);
    } else {
      const newImages = filteredFiles;
      setFiles((prevFiles) => ({
        ...prevFiles,
        images: [...prevFiles.images, ...newImages],
      }));
      setImagePreviews((prevPreviews) => ({
        ...prevPreviews,
        images: [
          ...prevPreviews.images,
          ...newImages.map((file) => ({ url: URL.createObjectURL(file) })),
        ],
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "price" ? parseFloat(value) : value,
    }));
  };

  const handleRemoveImage = (type, index, id) => {
    if (type === "main_image") {
      setFiles((prevFiles) => ({ ...prevFiles, main_image: null }));
      setImagePreviews((prevPreviews) => ({
        ...prevPreviews,
        main_image: null,
      }));
      setCountImages(0);
    } else {
      const newImages = files.images.filter((_, i) => i !== index - 1);
      setFiles((prevFiles) => ({ ...prevFiles, images: newImages }));
      const newImagePreviews = imagePreviews.images.filter(
        (_, i) => i !== index
      );
      setImagePreviews((prevPreviews) => ({
        ...prevPreviews,
        images: newImagePreviews,
      }));
      additionalImagesRef.current.value = null;
    }
    if (id) {
      setDeletedImages((prevDeleted) => [...prevDeleted, id]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const completeFormData = new FormData();
    completeFormData.append("name", formData.name);
    completeFormData.append("description", formData.description);
    completeFormData.append("price", formData.price);
    selectedItems.forEach((id) => {
      completeFormData.append(`category_ids[]`, id);
    });
    if (files.main_image) {
      completeFormData.append("main_image", files.main_image);
    }
    files.images.forEach((image) => {
      completeFormData.append(`images[]`, image);
    });

    deletedImages.forEach((id) => {
      completeFormData.append(`deleted_images[]`, id);
    });

    try {
      const response = await updateProduct(id, completeFormData);
      console.log(response);
      alert("Product updated successfully!");
      navigate("/Admin/products");
    } catch (error) {
      console.error(error);
      alert("An error occurred while updating the product.");
    }
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        await getProductByid(id, setEditproductData, setLoading);
      } catch (error) {
        console.error("Error fetching product:", error);
        setLoading(false);
      }
    };

    fetchProductData();
  }, [id]);

  useEffect(() => {
    if (!loading) {
      setFormData({
        name: editproductData.name || "",
        description: editproductData.description || "",
        price: parseFloat(editproductData.price) || 0,
        category_ids: editproductData.categories.map((cat) => cat.id) || [],
      });
      setImagePreviews({
        main_image: `${website}${editproductData.main_image}`,
        images: editproductData.media.map((img) => ({
          id: img.id,
          url: `${website}${img.url}`,
        })),
      });


      setSelectedItems(editproductData.categories.map((cat) => cat.id));
    }
  }, [loading]);

  useEffect(() => {
    const fetchHierarchy = async () => {
      try {
        const response = await fetch(
          `${webApi}/category-relations/hierarchy/100`
        );
        const data = await response.json();
        setHierarchy(data.hierarchy);
      } catch (error) {
        console.error("Error fetching the hierarchy:", error);
      }
    };

    fetchHierarchy();
  }, []);

  const handleItemClick = (item) => {
    const isAlreadySelected = selectedItems.some(
      (selectedItem) => selectedItem === item.id
    );

    if (!isAlreadySelected) {
      setSelectedItems((prevItems) => {
        const updatedItems = [...prevItems, item.id];
        return updatedItems;
      });
    }
  };

  const handleItemRemove = (index) => {
    setSelectedItems((prevItems) => {
      const updatedItems = prevItems.filter((_, i) => i !== index);
      return updatedItems;
    });
  };

  const renderSubmenu = (sub_categories) => {
    return sub_categories.map((sub) => (
      <li key={sub.id}>
        {sub.sub_categories.length > 0 ? (
          <>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => handleItemClick(sub)}
            >
              {sub.name}{" "}
              <span className="material-symbols-outlined">arrow_forward</span>
            </a>
            <ul className="dropdown-menu dropdown-submenu">
              {renderSubmenu(sub.sub_categories)}
            </ul>
          </>
        ) : (
          <a
            className="dropdown-item"
            href="#"
            onClick={() => handleItemClick(sub)}
          >
            {sub.name}
          </a>
        )}
      </li>
    ));
  };

  const renderMenu = (categories) => {
    return categories.map((category) => (
      <li key={category.id}>
        <a
          className="dropdown-item"
          href="#"
          onClick={() => handleItemClick(category)}
        >
          {category.name}{" "}
          <span className="material-symbols-outlined">arrow_forward</span>
        </a>
        {category.sub_categories.length > 0 && (
          <ul className="dropdown-menu dropdown-submenu">
            {renderSubmenu(category.sub_categories)}
          </ul>
        )}
      </li>
    ));
  };

  const handleMouseEnter = () => {
    setIsMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsMenuOpen(false);
  };

  const getCategoryNameById = (id, categories) => {
    for (const category of categories) {
      if (category.id === id) return category.name;
      if (category.sub_categories.length > 0) {
        const subCategoryName = getCategoryNameById(id, category.sub_categories);
        if (subCategoryName) return subCategoryName;
      }
    }
    return null;
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  return (
    <Container className="card shadow border-0 mt-auto">
      <div className="card-header bg-secondary bg-gradient ml-0 py-3 mb-4">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="text-white">Update Product</h1>
          </div>
        </div>
      </div>
      <Form className="card-components" onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col lg={6}>
            <Form.Group controlId="productName">
              <Form.Label className="title-text">Product Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Product Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={12}>
            <div className="dropdown-container mt-3 d-flex align-items-center">
              <div
                className="dropdown"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <button
                  className="btn-category d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  aria-expanded={isMenuOpen}
                >
                  Categories
                  <span className="material-symbols-outlined mt-1">
                    keyboard_arrow_down
                  </span>
                </button>
                <div className="list-value">
                  {isMenuOpen && (
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {renderMenu(hierarchy)}
                    </ul>
                  )}
                </div>
              </div>
              <div className="selected-items ms-3">
                {selectedItems.map((item, index) => (
                  <span key={index} className="selected-item">
                    <span
                      className="material-symbols-outlined"
                      style={{ cursor: "pointer", fontSize: "14px" }}
                      onClick={() => handleItemRemove(index)}
                    >
                      close
                    </span>
                    <span>
                      {getCategoryNameById(item, hierarchy)}
                    </span>
                  </span>
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={6}>
            <Form.Group controlId="productPrice">
              <Form.Label className="title-text">Product Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Product Price"
                name="price"
                value={formData.price}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={12}>
            <Form.Group controlId="productDescription">
              <Form.Label className="title-text">
                Product Description
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter Product Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col lg={6} className="mt-2">
            <Form.Group>
              <Form.Label className="title-text">Main Image</Form.Label>
              <Form.Label
                htmlFor="images"
                className="custom-file-label mt-4 me-2 ms-2"
              >
                {files.main_image ? files.main_image.name : "No image chosen"}
              </Form.Label>
              <Form.Control
                type="file"
                name="main_image"
                ref={mainImageRef}
                onChange={handleFileChange}
                onInvalid={() =>
                  alert(
                    "Please select files with extensions .jpg, .png, or .jpeg"
                  )
                }
                accept=".jpg, .jpeg, .png"
                className="custom-file-input"
                id="images"
              />
            </Form.Group>
          </Col>
          <Col lg={6} className="mt-2">
            <Form.Group>
              <Form.Label className="title-text">Additional Images</Form.Label>
              <Form.Label
                htmlFor="Addtionalimages"
                className="custom-file-label mt-4 me-2"
              >
                {imagePreviews.images.length > 0
                  ? `${imagePreviews.images.length} images`
                  : "No image chosen"}
              </Form.Label>
              <Form.Control
                type="file"
                name="images"
                multiple
                ref={additionalImagesRef}
                onChange={handleFileChange}
                onInvalid={() =>
                  alert(
                    "Please select files with extensions .jpg, .png, or .jpeg"
                  )
                }
                accept=".jpg, .jpeg, .png"
                className="custom-file-input"
                id="Addtionalimages"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3 no-margin">
          <Col lg={6}>
            {imagePreviews.main_image && (
              <div
                className="image-preview"
                style={{
                  position: "relative",
                  width: "300px",
                  height: "300px",
                }}
              >
                <img
                  src={imagePreviews.main_image}
                  alt="Main Preview"
                  style={{ width: "100%", height: "auto" }}
                />
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleRemoveImage("main_image")}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                  }}
                >
                  Remove
                </Button>
              </div>
            )}
          </Col>
          <Col lg={6}>
            {imagePreviews.images.length > 0 && (
              <Swiper
                navigation={true}
                modules={[Navigation]}
                className="mySwiperProduct"
              >
                {imagePreviews.images.map((image, index) => (
                  <SwiperSlide key={index} className="SwiperSlideProduct">
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "300px",
                      }}
                    >
                      <img
                        src={image.url}
                        alt={`Preview ${index}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() =>
                          handleRemoveImage("images", index, image.id)
                        }
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </Col>
        </Row>
        <div className="d-flex justify-content-center Modal-Footer">
          <Button
            className="Styled-Button Back-Button"
            variant="secondary"
            onClick={() => {
              navigate("/Admin/products");
            }}
          >
            Go Back
          </Button>
          <Button
            className="Styled-Button Create-Button"
            variant="primary"
            type="submit"
          >
            Update Product
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default UpdateProduct;
