import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ProductBarChart = () => {
  // Aggregate yearly sales for each product
  const yearlySalesData = products.map(product => {
    const totalSales = productSalesData
      .filter(item => item.product === product)
      .reduce((acc, item) => acc + item.sales, 0);
    return { name: product, sales: totalSales };
  });

  // Sort data to show top selling products at the top
  yearlySalesData.sort((a, b) => b.sales - a.sales);

  return (
    <ResponsiveContainer width="100%" height={600}>
      <BarChart
        data={yearlySalesData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="sales" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ProductBarChart;

const products = [
  "iPhone 13", "Samsung Galaxy S21", "Dell XPS 15", "MacBook Air", "Sony WH-1000XM4 Headphones",
  "Bose QuietComfort 35", "Nintendo Switch", "PlayStation 5", "Xbox Series X", "iPad Pro",
  "Amazon Kindle Paperwhite", "Google Pixel 6", "Fitbit Charge 4", "Nest Learning Thermostat", "Samsung QLED TV",
  "LG Refrigerator", "Huawei P40", "Canon EOS 5D", "Nikon D3500", "GoPro HERO9",
  "Dyson V11 Vacuum Cleaner", "Philips Hue Lightbulbs", "Rolex Submariner", "Tesla Model S", "Ford Mustang",
  "Toyota Corolla", "Honda Civic", "Chevrolet Silverado", "BMW 3 Series", "Mercedes-Benz C-Class",
  "Audi A4", "Porsche 911", "Volkswagen Golf", "Kia Optima", "Hyundai Sonata",
  "Asus ROG Laptop", "Microsoft Surface Pro 7", "Razer DeathAdder Mouse", "Adobe Photoshop CS6", "Norton Antivirus",
  "McAfee Total Protection", "Cisco Routers", "Sandisk SSD", "Seagate Hard Drive", "Western Digital External HDD",
  "Oculus Rift S", "Valve Index VR Kit", "Lego Star Wars Millennium Falcon", "Monopoly Classic Edition", "UNO Card Game",
  "Barbie Dreamhouse", "Hot Wheels Basic Car", "Fisher-Price Laugh & Learn Smart Stages Puppy", "Nerf N-Strike Elite Blaster", "Jenga Classic Game",
  "Samsung Galaxy Tab S7", "Apple Watch Series 6", "Garmin GPS Navigator", "TCL 4K Smart LED TV", "Sony PlayStation VR",
  "Instant Pot Duo 7-in-1 Electric Pressure Cooker", "Keurig K-Coffee Maker", "KitchenAid Stand Mixer", "Ninja Foodi Digital Fry", "Vitamix Blender",
  "Braun Electric Shaver", "Oral-B Electric Toothbrush", "Philips Norelco Multigroom", "Gillette Fusion ProGlide", "Dove Beauty Bar",
  "Neutrogena Hydro Boost Water Gel", "L'Oreal Paris Skincare", "Cetaphil Gentle Skin Cleanser", "Clinique Moisture Surge", "Estee Lauder Advanced Night Repair",
  
];


const productSalesData = [];
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


products.forEach(product => {
  months.forEach(month => {
    productSalesData.push({
      month: month,
      product: product,
      sales: Math.floor(Math.random() * 150) + 50 // Random sales number between 50 and 200
    });
  });
});
