import React, { useState, useEffect, useRef } from "react";
import { ListGroup, Button, Modal, Form } from "react-bootstrap";
import debounce from "lodash.debounce";

import "./CategoryModal.css";
import { createMainCategory, createSubCategory, getAllSearchCategories, getCategorieshierarchy, deleteCategoryRelation, deleteCategory } from "../../../utils/CategoryUtil";

const CategoryModal = (props) => {
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [expandedCategories, setExpandedCategories] = useState({});
  const [isAddingSubcategory, setIsAddingSubcategory] = useState(false);
  const [seeAll, setSeeAll] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(1000000000);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesData, setCategoriesData] = useState({ data: [], total: 0 });
  const [currentItems, setCurrentItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [showSubCategoryModal, setShowSubCategoryModal] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const [deleteConfirmationModalShow, setDeleteConfirmationModalShow] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState({ parent: null, child: null });
  const [files, setFiles] = useState({ main_image: null, images: [] });
  const mainImageRef = useRef(null);
  const handleFileChange = (e) => {
    const { name, files: inputFiles } = e.target;
    const filteredFiles = Array.from(inputFiles).filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return ["jpg", "png", "jpeg"].includes(extension);
    });

    if (filteredFiles.length === 0) {
      console.log("No valid files selected.");
      return;
    }

    if (name === "main_image") {
      setFiles((prevFiles) => ({
        ...prevFiles,
        main_image: filteredFiles[0],
      }));
    } else {
      setFiles((prevFiles) => ({
        ...prevFiles,
        images: [...prevFiles.images, ...filteredFiles],
      }));
    }
  };

  const fetchData = debounce(() => {
    getAllSearchCategories(
      currentPage,
      itemsPerPage,
      categoryName,
      setCategoriesData,
      setCurrentItems,
      setFilteredItems
    );
    getCategorieshierarchy(setCategories, categoryName);
  }, 300);

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, categoryName, seeAll]);

  const fetchSubData = debounce(() => {
    getAllSearchCategories(
      currentPage,
      itemsPerPage,
      subCategoryName,
      setCategoriesData,
      setCurrentItems,
      setFilteredItems
    );
  }, 300);

  useEffect(() => {
    fetchSubData();
  }, [currentPage, itemsPerPage, subCategoryName, seeAll]);

  const handleFilteredItemClick = (value) => {
    setSubCategoryName(value.name);
    setCurrentItems([]);
    setFilteredItems([]);
  };

  const handleSeeAllToggle = () => {
    setSeeAll(!seeAll);
    setItemsPerPage(!seeAll ? 100000 : 5);
  };

  const handleClick = () => {
    if (currentCategoryId) {
      handleAddSubCategory(currentCategoryId);
    } else {
      handleAddCategory();
    }
  };
  const handleAddCategory = async () => {
    if (!subCategoryName.trim()) return;
    try {
      const imageFile = files.images[0];
      const newCategory = await createMainCategory(subCategoryName, 1, imageFile);
      setCategories([...categories, { ...newCategory, sub_categories: [] }]);
      
    } catch (error) {
      console.error('Error adding category:', error);
    }finally {
      fetchData()
      setSubCategoryName("");
      setShowSubCategoryModal(false);
    }
  };

  const handleAddSubCategory = async (parentId) => {
    if (isAddingSubcategory) return;
    if (!subCategoryName.trim()) return;
    setIsAddingSubcategory(true);

    try {
      const imageFile = files.images[0];
      
      const newSubCategory = await createSubCategory(subCategoryName, 0, parentId, imageFile);

      setCategories(updateCategories(categories, parentId, newSubCategory));
      
      
      
    } catch (error) {
      console.error('Error adding subcategory:', error);
    } finally {
      fetchData()
      setIsAddingSubcategory(false);
      setShowSubCategoryModal(false)
      setSubCategoryName("");
    }
  };

  const updateCategories = (categories, parentId, newSubCategory) => {
    return categories.map((category) => {
      if (category.id === parentId) {
        return {
          ...category,
          sub_categories: [...(category.sub_categories || []), newSubCategory],
        };
      } else if (category.sub_categories) {
        return {
          ...category,
          sub_categories: updateCategories(category.sub_categories, parentId, newSubCategory),
        };
      } else {
        return category;
      }
    });
  };

  const handleToggleExpand = (id) => {
    setExpandedCategories((prevExpandedCategories) => ({
      ...prevExpandedCategories,
      [id]: !prevExpandedCategories[id],
    }));
  };

  const handleShowSubCategoryModal = (id) => {
    setCurrentCategoryId(id);
    setShowSubCategoryModal(true);
  };

  const findParentCategory = (categories, childId) => {
    let parentCategory = null;

    const searchCategory = (categoryList, parentId) => {
      for (let category of categoryList) {
        if (category.sub_categories.some(subCat => subCat.id === childId)) {
          parentCategory = category;
          return true;
        }
        if (category.sub_categories.length > 0) {
          if (searchCategory(category.sub_categories, category.id)) {
            return true;
          }
        }
      }
      return false;
    };

    searchCategory(categories, null);
    return parentCategory;
  };

  const handleDeleteNode = (childId) => {
    const parentCategory = findParentCategory(categories, childId);

    if (parentCategory) {
      setDeleteTarget({ parent: parentCategory.id, child: childId });
      setDeleteConfirmationModalShow(true);
    } else {
      setDeleteTarget({ parent: null, child: childId });
      setDeleteConfirmationModalShow(true);
    }
  };

  const handleDeleteConfirmation = async () => {
    setDeleteConfirmationModalShow(false);
    try {
      if (deleteTarget.parent) {
        await deleteCategoryRelation(deleteTarget.parent, deleteTarget.child);
      } else {
        await deleteCategory(deleteTarget.child);
      }
      fetchData();
    } catch (error) {
      console.error('Error deleting category relation:', error);
    }
  };

  const renderCategories = (categories) => (
    <li>
      {categories.map((category) => (
        <div key={category.id} className={`category-item-container ${expandedCategories[category.id] ? 'expanded' : ''}`}>
          <div className={`category-item ${expandedCategories[category.id] ? 'expanded' : ''}`}>
            <div className="d-flex" onClick={() => handleToggleExpand(category.id)}>
              <span className="material-symbols-outlined">
                {expandedCategories[category.id] ? "keyboard_arrow_down" : "keyboard_arrow_right"}
              </span>
              <span className="span-li">{category.name}</span>
            </div>
            <span className="material-symbols-outlined" onClick={() => handleDeleteNode(category.id)}>
              delete
            </span>
            {expandedCategories[category.id] && <div className="line"></div>}
          </div>
          <ul className={`submenu-item ${expandedCategories[category.id] ? 'expanded' : ''}`}>
            {expandedCategories[category.id] && (
              <>
                <div className="category-item">
                  <span className="btn-addsubcategory" onClick={() => handleShowSubCategoryModal(category.id)}>
                    <span className="material-symbols-outlined me-1">
                      add
                    </span>
                    Add Subcategory
                  </span>
                  {expandedCategories[category.id] && <div className="line"></div>}
                </div>
                {category.sub_categories && category.sub_categories.length > 0 && renderCategories(category.sub_categories)}
              </>
            )}
          </ul>
        </div>
      ))}
    </li>
  );
  

  return (
    <div className="sidebar-menu-category">
      <div className="sidebar-header-category">
        <div className="align-items-center main-category-input">
          <div >
            <input
              type="text"
              placeholder="Search hierarchy"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              className="inputModal"
            />
           
            <span className="btn-addsubcategory" onClick={() => handleShowSubCategoryModal()}>
                    <span className="material-symbols-outlined me-1">
                      add
                    </span>
                    Add MainCategory
                  </span>
          </div>
        </div>
      </div>
      <div className="sidebar-body-category">
        {renderCategories(categories)}
      </div>

      <Modal show={showSubCategoryModal} onHide={() => setShowSubCategoryModal(false)} centered className="modal-category">
        <Modal.Header closeButton>
          <Modal.Title>{currentCategoryId? " Add Sub-Category" : "Add Main-Category"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="align-items-center main-category-input">
            <div className="mt-2 mb-4">
              <Form.Group>
                <Form.Label className="title-text">Category Image</Form.Label>
                <Form.Control
                  type="file"
                  name="images"
                  multiple
                  ref={mainImageRef}
                  onChange={handleFileChange}
                  onInvalid={() =>
                    alert(
                      "Please select files with extensions .jpg, .png, or .jpeg"
                    )
                  }
                  accept=".jpg, .jpeg, .png"
                  id="Addtionalimages"
                />
              </Form.Group>
            </div>
            <div className="search__bar">
            <div className="Search__Add mb-2">
              <input
                type="text"
                placeholder="Enter a Category"
                value={subCategoryName}
                onChange={(e) => setSubCategoryName(e.target.value)}
                className="inputModal-sub"
              />
            </div>
            {subCategoryName && (
              <div className="scrollable-category-list">
                <ListGroup>
                  {currentItems.map((result) => (
                    <ListGroup.Item
                      key={result.id}
                      className="listItem-category"
                      onClick={() => handleFilteredItemClick(result)}
                    >
                      {result.name}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSubCategoryModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClick}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteConfirmationModalShow} onHide={() => setDeleteConfirmationModalShow(false)} centered className="modal-category">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this category relation?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteConfirmationModalShow(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirmation}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CategoryModal;
