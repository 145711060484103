import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { SignUp } from "./SignUp_utils";
import { useAuth } from "../AuthContext/AuthContext";
import "../Login/Login.css";
import "./SignUp.css";

const Register = () => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const { login } = useAuth();

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const name = firstName + " " + lastName;

  const handleSignup = async () => {
    const result = await SignUp(email, password, name);
    console.log(result);
    if (result.success) {
      toast.success(result.message, {
        position: "top-center",
        duration: 3000,
        richColors: true,
      });
      await login(result.data.role);

      if (result.data.role === "admin") {
        navigate("/Admin");
      } else {
        navigate("/");
      }
    } else {
      toast.error(result.message, {
        position: "top-center",
        duration: 3000,
        richColors: true,
      });
    }
  };
  return (
    <>
      <div className="signup-form-container">
        <p className="title">Welcome</p>
        <form className="form">
          <div className="name-input">
            <input
              type="text"
              className="fname-input"
              placeholder="First Name"
              onChange={handleFirstNameChange}
              value={firstName}
            />
            <input
              type="text"
              className="lname-input"
              placeholder="Last Name"
              onChange={handleLastNameChange}
              value={lastName}
            />
          </div>
          <input
            type="email"
            className="input"
            placeholder="Email"
            onChange={handleEmailChange}
            value={email}
          />
          <input
            type="password"
            className="input"
            placeholder="Password"
            onChange={handlePasswordChange}
            value={password}
          />
          <input
            type="password"
            className="input"
            placeholder="Confirm Password"
            onChange={handleConfirmPasswordChange}
            value={ConfirmPassword}
          />
          <button className="form-btn" onClick={handleSignup}>
            Sign Up
          </button>
          <Link to="/login" className="sign-in-label">
            Already have an account? Log in
          </Link>
        </form>
      </div>
    </>
  );
};

export default Register;
