import React, { createContext, useContext, useState, useEffect } from "react";
import { postAuth } from "../../utils/GlobalUtils";

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState("user");

  const checkAuthentication = async () => {
    try {
      const response = await postAuth("/auth/check");
      const { authenticated, role: userRole } = response.data;

      setIsAuthenticated(authenticated);
      setRole(authenticated ? userRole : "user");
    } catch (error) {
      console.error("Failed to verify authentication", error);
      setIsAuthenticated(false);
    }
  };

  const login = (userRole) => {
    setIsAuthenticated(true);
    setRole(userRole);
  };

  const logout = async () => {
    try {
      await postAuth('/users/logout', {});
      setIsAuthenticated(false);
      setRole("user");
     
     
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };



  return (
    <AuthContext.Provider
      value={{ isAuthenticated, role, checkAuthentication, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
