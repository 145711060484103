import { post } from "../../utils/GlobalUtils";

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
const phoneRegex = /^[0-9]{10}$/;

export const validateName = (name) => {
  return nameRegex.test(name);
};

export const validateEmail = (email) => {
  return emailRegex.test(email);
};

export const validatePassword = (password) => {
  return passwordRegex.test(password);
};

export const validatePasswordMatch = (password, confirmPassword) => {
  return password === confirmPassword;
};

export const validatePhone = (phone) => {
  return phoneRegex.test(phone);
};

const validateSignupData = ({ email, password, name, phone }) => {
  if (!validateEmail(email)) {
    return "Invalid email";
  }
  if (!validatePassword(password)) {
    return "Invalid password";
  }
  if (!validateName(name)) {
    return "Invalid name";
  }
  if (!validatePhone(phone)) {
    return "Invalid phone number";
  }
  return null;
};

export const SignUp = async (email, password, name) => {
  const validationError = validateSignupData({ email, password, name });
  if (validationError) {
    return { success: false, message: validationError };
  }

  try {
    const response = await post("/users/signup", {
      email: email,
      password: password,
      name: name,
    });

    if (!response.ok) {
      return {
        success: false,
        message:
          response.message ||
          "Failed to sign up, server responded with an error.",
      };
    }

    return { success: true, message: "Signup successful", data: response };
  } catch (error) {
    console.error("Error signing up:", error);
    return {
      success: false,
      message: "Signup failed due to a network or server error.",
    };
  }
};
