import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Button, Pagination } from "react-bootstrap";
import { createInventoryProduct, getAllInventories } from "../../../utils/InventoriesUtil";
import { getAllProducts } from "../../../utils/ProductUtil";
import { website } from "../../../utils/GlobalUtils";
import ProductDropdown from "../productMangment/ProductDropdown";
import "./AddInventoryProduct.css";

function AddInventoryProduct(props) {
  const dropdownRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);
  const [inventoryData, setInventoryData] = useState({ data: [], total: 0 });
  const [selectedInventoryId, setSelectedInventoryId] = useState(props.editinventoryData.id);
  const [InventoryId, setInventoryId] = useState(0);
  const [currentItemsProduct, setCurrentItemsProduct] = useState([]);
  const [searchTermProduct, setSearchTermProduct] = useState("");
  const [productsData, setProductsData] = useState({ data: [], total: 0 });
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    location: "",
  });
  
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const handleSearchProduct = (value) => {
    setSearchTermProduct(value);
  };

  const fetchData = () => {
    getAllInventories(
      currentPage,
      itemsPerPage,
      searchTerm,
      setInventoryData,
      setCurrentItems
    );

    getAllProducts(
      currentPage,
      itemsPerPage,
      searchTermProduct,
      minPrice,
      maxPrice,
      selectedItems,
      InventoryId,
      setProductsData,
      setCurrentItemsProduct,
      setLoading
    );
  };

  useEffect(() => {
    if (props.editinventoryData && props.editinventoryData.id) {
      setSelectedInventoryId(props.editinventoryData.id);
    }
  }, [props.editinventoryData]);

  useEffect(() => {
    if (props.show) {
      fetchData();
    }
  }, [currentPage, itemsPerPage, searchTerm, searchTermProduct, selectedItems, props.show]);

  const handleSelectedItemsChange = (items) => {
    setSelectedItems(items);
  };

  const handlePerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!selectedInventoryId) {
      console.error("No inventory selected.");
      return;
    }

    const productsPayload = selectedProducts.map(product => ({
      product_id: product.id,
      quantity: product.quantity
    }));

    const finalData = {
      inventory_id: selectedInventoryId,
      products: productsPayload
    };
console.log("final data",finalData)
    try {
      const response = await createInventoryProduct(finalData.inventory_id,finalData.products);
      console.log("Inventory updated successfully:", response);

      setSelectedInventoryId(null);
      setSelectedProducts([]);

      props.onHide();
    } catch (error) {
      console.error("Error updating inventory:", error);
    }
  };

  const handleBackToList = () => {
    props.onHide();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleProductClick = (product) => {
    setSelectedProducts((prevSelected) => {
      const isAlreadySelected = prevSelected.find(
        (item) => item.id === product.id
      );
      
      if (isAlreadySelected) {
        return prevSelected.filter((item) => item.id !== product.id); 
      } else {
        return [...prevSelected, { ...product, quantity: 0 }];
      }
    });
  };

  const handleInventoryClick = (inventoryId) => {
    setSelectedInventoryId(inventoryId);
  };

  const handleRemoveFromSelected = (productId) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.filter((item) => item.id !== productId)
    );
  };

  const handleQuantityChange = (productId, quantity) => {
    setSelectedProducts((prevSelected) => 
      prevSelected.map((product) => 
        product.id === productId ? { ...product, quantity } : product
      )
    );
  };

  const totalItems = inventoryData.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const isProductSelected = (productId) => {
    return selectedProducts.some(product => product.id === productId);
  };
  const isInventorySelected = (inventoryId) => {
    return selectedInventoryId === inventoryId;
  };

  return (
    <Modal {...props} centered fullscreen={true} scrollable={true}>
      <Modal.Body>
        <div className="d-flex w-100 h-100">
          <Row className="mb-3 w-100 h-100 mt-3">
            <Col lg={4}>
              <h2 className="text-center">Inventories</h2>
              <div className="justify-content-center d-flex">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => handleSearchChange(e.target.value)}
                  className="search-inventory-List"
                />
              </div>
              <div className="invetorydataTable">
                <table className="inventory-List">
                  <tbody>
                    {currentItems.length > 0 ? (
                      currentItems.map((item) => (
                        <tr key={item.id}
                          onClick={() => handleInventoryClick(item.id)}
                          className={isInventorySelected(item.id) ? "product-selected" : "inventory-cell"}
                        >
                          <td>
                            <div className="d-flex align-items-center">
                              {item.name}-{item.location}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">No items found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="per-page">
                  <select value={itemsPerPage} onChange={handlePerPageChange}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                  </select>
                </div>
                <Pagination className="mt-3">
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  <Pagination.Item disabled>
                    Page {currentPage} of {totalPages}
                  </Pagination.Item>
                  <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            </Col>
            <Col lg={4}>
              <h2 className="text-center">Products</h2>
              <div className="justify-content-start d-flex align-items-center">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTermProduct}
                  onChange={(e) => handleSearchProduct(e.target.value)}
                  className="search-product-List"
                />
                <ProductDropdown
                  ref={dropdownRef}
                  onSelectedItemsChange={handleSelectedItemsChange}
                  CreateProduct={false}
                />
              </div>
              <div
                className="invetorydataTable"
                style={{ marginTop: selectedItems.length > 0 ? "40px" : "0px" }}
              >
                <table className="inventory-List">
                  <tbody>
                    {currentItemsProduct.length > 0 ? (
                      currentItemsProduct.map((item) => (
                        <tr
                          key={item.id}
                          onClick={() => handleProductClick(item)}
                          className={isProductSelected(item.id) ? "product-selected" : "inventory-cell"}
                        >
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="image-container">
                                <img
                                  src={website + item.main_image}
                                  alt="Main Image"
                                  className="imgMain"
                                />
                              </div>
                              {item.name}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">No items found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="per-page">
                  <select value={itemsPerPage} onChange={handlePerPageChange}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                  </select>
                </div>
                <Pagination className="mt-3">
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  <Pagination.Item disabled>
                    Page {currentPage} of {totalPages}
                  </Pagination.Item>
                  <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            </Col>
            <Col lg={4}>
              <h2 className="text-center" style={{ marginBottom: "55px" }}>
                Chosen Products
              </h2>

              <div className="chosen-products">
                <div className="invetorydataTable">
                  <table
                    className="inventory-List"
                    style={{ marginRight: "10px" }}
                  >
                    <tbody>
                      {selectedProducts.map((item) => (
                        <tr key={item.id} className="inventory-cell">
                          <td>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center">
                                <div className="image-container">
                                  <img
                                    src={website + item.main_image}
                                    alt="Main Image"
                                    className="imgMain"
                                  />
                                </div>
                                {item.name}
                              </div>
                              <div className="d-flex justify-content-center align-items-center mt-2">
                                <input
                                  type="number"
                                  value={item.quantity}
                                  min={0}
                                  onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value, 10))}
                                  style={{ width: "50px", marginBottom: "10px" }}
                                />
                                <button
                                  onClick={() => handleRemoveFromSelected(item.id)}
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                  }}
                                >
                                  <span className="material-symbols-outlined">
                                    close
                                  </span>
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex Modal-Footer">
        <Button
          className="Styled-Button Back-Button"
          variant="secondary"
          onClick={handleBackToList}
        >
          Go Back
        </Button>
        <Button
          className="Styled-Button Create-Button"
          variant="primary"
          type="submit"
          onClick={handleSubmit}
        >
          Create Inventory Product
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddInventoryProduct;
