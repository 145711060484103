import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import ProductGallery from "./products/ProductGallery";
import "./ProductDetails.css";
import { useNavigate,Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { getProductByid } from "./utils/ProductUtil";

import Spinner from "./Spinner";
import ProductSection from "./mainSection/ProductSection";

const ProductDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentItems, setCurrentItems] = useState({});
  const [loading, setLoading] = useState(true);
 

  const fetchData = () => {
    getProductByid(id, setCurrentItems, setLoading);
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const handleNavigate = () => {
    navigate('/ContactUs'); 
  };

  return loading ? (
    <Spinner loading={loading} />
  ) : (
    <Container>
      <Row className="mt-5 mb-5" style={{ gap: "20px" }}>
        <h4 className="breadcrumb-style">
          <Link to={"/"}>Home</Link> /{" "}
          <Link
          
            onClick={(e) => {
              e.preventDefault();
              navigate(`/productList`, {
                state: { categoryId:[] },
              });
            }}
          >
           Products
          </Link> /{" "}
          <Link
          
            onClick={(e) => {
              e.preventDefault();
              navigate(`/productList`, {
                state: { categoryId: [currentItems.categories[0].id] },
              });
            }}
          >
           {currentItems.categories[0].name}
          </Link>
          
        </h4>
        
        <Col
          lg={6}
          className="p-3 pr-5 h-50 d-flex row align-items-center justify-content-center"
        >
          <ProductGallery
            main_image={currentItems.main_image}
            media={currentItems.media}
          />
        </Col>
        <Col lg={5} className="p-3">
          <h1 class="title">{currentItems.name} </h1>
          {currentItems.categories.map((item, index) => (
        <span key={index} className="colorCat bullet-separated">
          {item.name}
        </span>
      ))}
          <hr />

          <h5>Description</h5>
          <p>
           {currentItems.description}
          </p>
          {/* <h5>Size</h5> */}

          {/* <div className="size">
            <span>UK 6</span>
            <span>UK 7</span>
            <span>UK 8</span>
          </div> */}
          <br />

          <button
            class="button-18"
            onClick={() => {
              navigate("/ContactUs");
            }}
          >
            Enquire
            <span className="material-icons-outlined">message</span>
          </button>
        </Col>
      </Row>
      <hr />
      <Row>
       
        <Col lg={12}>
        <ProductSection title={"Similar Products"} id={currentItems.categories[0].id}/>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetails;
