import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef
} from "react";
import "./ProductDropdown.css";
import "swiper/swiper-bundle.css";
import { webApi } from "../../../utils/GlobalUtils";

const ProductDropdown = forwardRef(
  ({ onSelectedItemsChange, catitems, isopened, CreateProduct }, ref) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItemsName, setSelectedItemsName] = useState([]);
    const [hierarchy, setHierarchy] = useState([]);
    const [catItemsProcessed, setCatItemsProcessed] = useState(false);
  
    const scrollContainer = useRef(null);

    const scrollLeft = () => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollBy({ left: -200, behavior: 'smooth' });
      }
    };
  
    const scrollRight = () => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollBy({ left: 200, behavior: 'smooth' });
      }
    };
   

    useEffect(() => {
      const fetchHierarchy = async () => {
        try {
          const response = await fetch(
            `${webApi}/category-relations/hierarchy/8`
          );
          const data = await response.json();
          setHierarchy(data.hierarchy);
        } catch (error) {
          console.error("Error fetching the hierarchy:", error);
        }
      };

      fetchHierarchy();
    }, []);

    useEffect(() => {
      onSelectedItemsChange(selectedItems);
    }, [selectedItems, onSelectedItemsChange]);

    const handleMouseEnter = () => setIsMenuOpen(true);
    const handleMouseLeave = () => setIsMenuOpen(false);

    useEffect(() => {
      if (catitems && !catItemsProcessed) {
        setSelectedItems(catitems.map((item) => item.id));
        setSelectedItemsName(catitems);

        setCatItemsProcessed(true);
      }
    }, [catitems, catItemsProcessed]);

    const handleItemClick = (item) => {
      const isAlreadySelected = selectedItems.some(
        (selectedItem) => selectedItem === item.id
      );

      if (!isAlreadySelected) {
        setSelectedItems((prevItems) => {
          const updatedItems = [...prevItems, item.id];
          return updatedItems;
        });

        setSelectedItemsName((prevItems) => {
          const updatedItems = [...prevItems, { id: item.id, name: item.name }];
          return updatedItems;
        });
      }
    };

    const handleItemRemove = (index) => {
      setSelectedItems((prevItems) => {
        const updatedItems = prevItems.filter((_, i) => i !== index);
        return updatedItems;
      });

      setSelectedItemsName((prevItems) => {
        const updatedItems = prevItems.filter((_, i) => i !== index);
        return updatedItems;
      });
    };

    useImperativeHandle(ref, () => ({
      clearSelectedItems: () => {
        setSelectedItems([]);
        setSelectedItemsName([]);
      },
    }));

    const renderSubmenu = (sub_categories) => {
      return sub_categories.map((sub) => (
        <li key={sub.id}>
          {sub.sub_categories.length > 0 ? (
            <>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => handleItemClick(sub)}
              >
                {sub.name}{" "}
                <span className="material-symbols-outlined">arrow_forward</span>
              </a>
              <ul className="dropdown-menu dropdown-submenu">
                {renderSubmenu(sub.sub_categories)}
              </ul>
            </>
          ) : (
            <a
              className="dropdown-item"
              href="#"
              onClick={() => handleItemClick(sub)}
            >
              {sub.name}
            </a>
          )}
        </li>
      ));
    };

    const renderMenu = (categories) => {
      return categories.map((category) => (
        <li key={category.id}>
          <a
            className="dropdown-item"
            href="#"
            onClick={() => handleItemClick(category)}
          >
            {category.name}{" "}
            <span className="material-symbols-outlined">arrow_forward</span>
          </a>
          {category.sub_categories.length > 0 && (
            <ul className="dropdown-menu dropdown-submenu">
              {renderSubmenu(category.sub_categories)}
            </ul>
          )}
        </li>
      ));
    };

    return CreateProduct ? (
      <div className="dropdown-container mt-3 d-flex  align-items-center">
        <div
          className="dropdown"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <button
            className="btn-category d-flex align-items-center"
            type="button"
            id="dropdownMenuButton"
            aria-expanded={isMenuOpen}
          >
            Categories
            <span className="material-symbols-outlined mt-1">
              keyboard_arrow_down
            </span>
          </button>
          <div className="list-value">
            {isMenuOpen && (
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {renderMenu(hierarchy)}
              </ul>
            )}
          </div>
        </div>

        <div className="selected-items ms-3">
          {selectedItemsName.map((item, index) => (
            <span key={index} className="selected-item">
              <span
                className="material-symbols-outlined"
                style={{ cursor: "pointer", fontSize: "14px" }}
                onClick={() => handleItemRemove(index)}
              >
                close
              </span>
              <span>{item.name}</span>
            </span>
          ))}
        </div>
      </div>
    ) : (
      <div
        className=" justify-content-end d-flex flex-column align-items-center w-100"
        style={{ position: "absolute", top: "48px", zIndex: "10" }}
      >
        <div
          className="dropdown  "
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ marginLeft: "68%" }}
        >
          <button
            className=" btn-category2 d-flex align-items-center"
            type="button"
            aria-expanded={isMenuOpen}
            
          >
            Categories
            <span className="material-symbols-outlined mt-1">
              keyboard_arrow_down
            </span>
          </button>
          <div className="list-value">
            {isMenuOpen && (
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {renderMenu(hierarchy)}
              </ul>
            )}
          </div>
        </div>
        <div className="selected-productdropdown" ref={scrollContainer}>
          {selectedItemsName.length > 0 &&
            selectedItemsName.map((item, index) => (
              <div key={index} className="chip">
                
                <span>{item.name}</span>
                <span
                  className="material-symbols-outlined"
                  style={{ cursor: "pointer", fontSize: "14px" }}
                  onClick={() => handleItemRemove(index)}
                >
                  close
                </span>
              </div>
              
            ))}
           
        </div>
        {selectedItemsName.length > 3 && (
        <>
          <button className="arrow arrow-left" onClick={scrollLeft}>
            <span className="material-symbols-outlined">
              arrow_left_alt
            </span>
          </button>
          <button className="arrow arrow-right" onClick={scrollRight}>
            <span className="material-symbols-outlined">
              arrow_right_alt
            </span>
          </button>
        </>
      )}
      </div>
    );
  }
);

export default ProductDropdown;
