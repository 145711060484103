import React from "react";
import { ScaleLoader } from "react-spinners";

const Spinner = ({ loading }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#f5f5f5",
       
      }}
    >
      <h1>Mdagher</h1>

      <ScaleLoader color="#000000" loading={loading} size={150} />
    </div>
  );
};

export default Spinner;
