import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LineChart,
  Line,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const ProductScatterChart = () => {
  return (
    <Row>
      <Col lg="6">
        <div
          style={{
            borderRadius: "15px",
            marginTop: "20px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            padding:"10px"
          }}
        >
          <h5>products</h5>
          <ScatterChart
            width={600}
            height={400}
            margin={{ top: 20, right: 50, bottom: 20, left: 20 }}
          >
            <CartesianGrid />
            <XAxis type="category" dataKey="month" name="Month" />
            <YAxis type="number" dataKey="sales" name="Sales" unit=" units" />
            <Tooltip cursor={{ strokeDasharray: "3 3" }} />
            <Legend />
            {products.map((product, index) => (
              <Scatter
                key={index}
                name={product}
                data={productSalesData.filter(
                  (item) => item.product === product
                )}
                fill={`hsl(${(index * 60) % 360}, 70%, 60%)`} // Generate unique colors for each product
              />
            ))}
          </ScatterChart>
        </div>
      </Col>
      <Col
        lg="6"
        style={{
          borderRadius: "15px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
          marginTop: "20px",
          padding:"10px"
        }}
      >
       <div>
         <h5>New Accounts</h5>
         <ResponsiveContainer width="100%" height={300}>
           <LineChart
             data={data}
             margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
           >
             <CartesianGrid strokeDasharray="3 3" />
             <XAxis dataKey="name" />
             <YAxis />
             <Tooltip content={<CustomTooltip />} />
             <Line
               type="monotone"
               dataKey="uv"
               stroke="#82ca9d"
               strokeWidth={2}
               dot={{ r: 6 }}
               activeDot={{ r: 8 }}
             />
           </LineChart>
         </ResponsiveContainer>
       </div>
      </Col>
    </Row>
  );
};

export default ProductScatterChart;

const productSalesData = [];
const products = [
  "iPhone X",
  "Dell Latitude",
  "Samsung TV320",
  "Fridge Concorde",
  "Range Rover",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

products.forEach((product) => {
  months.forEach((month) => {
    productSalesData.push({
      month: month,
      product: product,
      sales: Math.floor(Math.random() * 150) + 50, // Random sales number between 50 and 200
    });
  });
});

const data = [
  { name: "Jan", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Feb", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Mar", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Apr", uv: 2780, pv: 3908, amt: 2000 },
  { name: "May", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Jun", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Jul", uv: 3490, pv: 4300, amt: 2100 },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          background: "rgba(255, 255, 255, 0.9)",
          border: "1px solid #ccc",
          borderRadius: "5px",
          padding: "10px",
        }}
      >
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};
