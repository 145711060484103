import React, { useState } from "react";
import { SearchProvider } from "./components/header/SearchContext";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import SideBarAdmin from "./components/admin/navigationBarAdmin/SideBarAdmin";
import UsersDataTables from "./components/admin/adminDataTables/userMangment/UsersDataTables";
import ProductDataTable from "./components/admin/adminDataTables/productMangment/ProductDataTable";
import CategoryDataTable from "./components/admin/adminDataTables/categoryMangment/CategoryDataTable";
import AdminDashBoard from "./components/admin/AdminDashBoard";
import Navbar from "./components/header/Navbar";
import LandingPage from "./components/LandingPage";
import Login from "./components/authentication/Login/Login";
import Register from "./components/authentication/SignUp/SignUp";
import ProductReviews from "./components/admin/adminDataTables/productMangment/ProductReviews";
import ProductRecycleBin from "./components/admin/adminDataTables/productMangment/ProductRecycleBin";
import ProductDetails from "./components/ProductDetails";
import InventoriesDataTable from "./components/admin/adminDataTables/inventoryMangment/InventoriesDataTable";
import InventoryRecycleBin from "./components/admin/adminDataTables/inventoryMangment/InventoryRecycleBin";
import ProductsList from "./components/ProductsList";
import AddProductByUrl from "./components/admin/adminDataTables/productMangment/AddProductByUrl";
import UserProduct from "./components/admin/adminDataTables/User-Products/UserProduct";
import CategoryModal from "./components/admin/adminDataTables/categoryMangment/CategoryModal";
import ContactUs from "./components/footer/ContactUs";
import AboutSection from "./components/aboutUs/AboutSection ";
import Footer from "./components/footer/Footer";
import EditCategoryModal from "./components/admin/adminDataTables/categoryMangment/EditCategoryModal";
import UpdateProduct from "./components/admin/adminDataTables/productMangment/UpdateProduct";
import ContactUsDataTable from "./components/admin/adminDataTables/contactus/ContactUsDataTable";

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

const AdminRoutes = () => {
  return (
    <div>
      <div className="d-flex">
        <SideBarAdmin />
        <Routes>
          <Route path="/Admin" element={<AdminDashBoard />} />
          <Route path="/Admin/Users" element={<UsersDataTables />} />
          <Route path="/Admin/products" element={<ProductDataTable />} />
          <Route path="/Admin/productUser" element={<UserProduct />} />
          <Route path="/Admin/products" element={<ProductDataTable />} />
          <Route
            path="/Admin/UpdateProduct/:id"
            element={
              
                <UpdateProduct />
             
            }
          />
          <Route path="/Admin/inventory" element={<InventoriesDataTable />} />
          <Route
            path="/Admin/inventoryProduct"
            element={<InventoriesDataTable />}
          />
          <Route path="/Admin/categories" element={<CategoryDataTable />} />
          <Route path="/Admin/createcategory" element={<CategoryModal />} />
          <Route path="/Admin/EditCategoryModal" element={<EditCategoryModal />} />
          <Route path="/Admin/ContactUs" element={<ContactUsDataTable />} />
         

          <Route
            path="/Admin/ProductReviews/:id"
            element={<ProductReviews />}
          />
          <Route
            path="/Admin/ProductRecycleBin"
            element={<ProductRecycleBin />}
          />
          <Route path="/Admin/ProductByUrl" element={<AddProductByUrl />} />
          <Route
            path="/Admin/InventoryRecycleBin"
            element={<InventoryRecycleBin />}
          />
        </Routes>
      </div>
    </div>
  );
};

const LandingPageRoute = () => {
  return (
    <>
      <SearchProvider>
        <Navbar />

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/AboutUs" element={<AboutSection />} />
          <Route path="/productPage/:id" element={<ProductDetails />} />
          <Route path="/productList" element={<ProductsList />} />
        </Routes>
        <Footer />
      </SearchProvider>
    </>
  );
};

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
    </Routes>
  );
};

const AppContent = () => {
  const location = useLocation();
  const isAdminPage = location.pathname.startsWith("/Admin");
  const isAuthPage =
    location.pathname.toLowerCase() === "/login" ||
    location.pathname.toLowerCase() === "/register";

  return (
    <>
      {isAdminPage ? (
        <AdminRoutes />
      ) : isAuthPage ? (
        <AuthRoutes />
      ) : (
        <>
          <LandingPageRoute />
        </>
      )}
    </>
  );
};

export default App;
