import React from 'react'
import CategoryChart from './charts/CategoryChart'
import AdminMainBox from './adminHomePage/AdminMainBox'
import { Container } from 'react-bootstrap'
import ProductScatterChart from './charts/ProductScatterChart '
import ProductBarChart from './charts/ProductBarChart '
const AdminDashBoard = () => {
  return (
    <Container >
      <AdminMainBox/>
      <CategoryChart />
      <ProductScatterChart/>
      <ProductBarChart/>
    </Container>
  )
}

export default AdminDashBoard
