import { getCookie } from "./cookieUtils";
import { getAuth, postAuth, deleteAuth, get, putAuth } from "./GlobalUtils";
import { useCallback, useState } from "react";

export const getCategories = async (
  currentPage,
  itemsPerPage,
  setCategoriesData,
  setLoading,
  setCurrentItems
) => {
  try {
    const response = await getAuth(
      `/categories/all/${itemsPerPage}?page=${currentPage}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }


    const data = await response.json();
    setCategoriesData({
      data: data.data,
      total: data.total,
    });
    setCurrentItems(data.data);
  } catch (error) {
    console.error("Error fetching categories:", error);
  } finally {
    setLoading(false);
  }
};
export const getMainCategories = async (
  setCurrentItems,

 
) => {
  try {
    const response = await getAuth(
      `/categories/main`,
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
  
    setCurrentItems(data.main_categories);
  } catch (error) {
    console.error("Error fetching categories:", error);
  }
};

export const getSearchCategories = async (
  currentPage,
  itemsPerPage,
  searchTerm,
  isMainCategory,
  setCategoriesData,
  setCurrentItems,
  setFilteredItems
) => {
  try {
    const response = await getAuth(
      `/categories/search/${itemsPerPage}?page=${currentPage}&name=${searchTerm}&is_main_category=${isMainCategory}`
    );

    const data = await response.json();
    setCategoriesData({
      data: data.data,
      total: data.total,
    });
    setCurrentItems(data.data);
  } catch (error) {
    console.error("Error fetching categories:", error);
  }
};
export const getAllSearchCategories = async (
  currentPage,
  itemsPerPage,
  searchTerm,
  setCategoriesData,
  setCurrentItems,
  setFilteredItems
) => {
  try {
    const response = await getAuth(
      `/categories/search/${itemsPerPage}?page=${currentPage}&name=${searchTerm}`
    );

    const data = await response.json();
    setCategoriesData({
      data: data.data,
      total: data.total,
    });

    setCurrentItems(data.data);
  } catch (error) {
    console.error("Error fetching categories:", error);
  }
};
export const getCategorieshierarchy = async (Setcategories, name) => {
  try {
    const url = name
      ? `/category-relations/hierarchy/${100}?name=${encodeURIComponent(name)}`
      : `/category-relations/hierarchy/${100}`;

    const response = await get(url);

    const data = await response.json();

    Setcategories(data.hierarchy);
  } catch (error) {
    console.error("Error fetching categories:", error);
  }
};

export const createMainCategory = async (name, is_main_category, image) => {
  try {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("is_main_category", is_main_category);
    if (image) {
      formData.append("images[]", image);
    }

    const response = await postAuth("/categories", formData);
    return response.data.category;
  } catch (error) {
    console.error("Error creating category:", error);
    throw error;
  }
};

export const createSubCategory = async (
  name,
  is_main_category,
  parent_id,
  image
) => {
  try {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("is_main_category", is_main_category);
    formData.append("parent_id", parent_id);

    if (image) {
      formData.append("images[]", image);
    }

    const response = await postAuth("/categories", formData);
    return response.data.category;
  } catch (error) {
    console.error("Error creating subcategory:", error);
    throw error;
  }
};

export const deleteCategory = async (id) => {
  try {
    await deleteAuth(`/categories/${id}`);

    console.log(`Category with ID ${id} has been deleted.`);
  } catch (error) {
    console.error("Error deleting category:", error);
    throw error;
  }
};

export const deleteCategoryRelation = async (Parentid, Childid) => {
  try {
    await deleteAuth(
      `/category-relations/delete/parent-child?parent=${Parentid}&child=${Childid}`
    );

    console.log(
      `Category with ID ${Parentid} parent and  ${Childid} child has been deleted.`
    );
  } catch (error) {
    console.error("Error deleting category:", error);
    throw error;
  }
};
export const deleteCategoryRelationRoot = async (Childid) => {
  try {
    await deleteAuth(
      `/category-relations/delete/parent-child?child=${Childid}`
    );

    console.log(`Category with ID   ${Childid} child has been deleted.`);
  } catch (error) {
    console.error("Error deleting category:", error);
    throw error;
  }
};

export const setOtherParent = async (oldParentId, parent_id, child_id) => {
  try {
    const response = await putAuth(
      `/category-relations?oldParentId=${oldParentId}&oldChildId=${child_id}`,
      { parent_id, child_id }
    );
    console.log(`done`);

    return response;
  } catch (error) {
    console.error("Error in set other parents:", error);
    throw error;
  }
};

export const updateCategory = async (id,formDataCategory) => {
  try {
    const response = await postAuth(`/categories/update/${id}`, formDataCategory);
    console.log(`done`);

    return response;
  } catch (error) {
    console.error("Error in set other parents:", error);
    throw error;
  }
};

export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
  const [translate, setTranslate] = useState(defaultTranslate);
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 2, y: height / 5 });
    }
  }, []);
  return [translate, containerRef];
};
