import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { createInventory } from '../../../utils/InventoriesUtil';

function InventoryModal(props) {
  const [formData, setFormData] = useState({
    name: "",
    location: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name.trim()) return;
    try {
      const newInventory = await createInventory(formData.name, formData.location);
      setFormData({
        name: "",
        location: "",
      });
    } catch (error) {
      console.error('Error adding inventory:', error);
    }
  };

  const handleBackToList = () => {
    props.onBackToList();
    props.onHide();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Modal {...props} centered fullscreen={true} scrollable={true}>
      <Modal.Body>
        <Container className="card shadow border-0 modal-container">
          <div className="card-header bg-secondary bg-gradient ml-0 py-3 mb-4">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="text-white"> Create Inventory </h1>
              </div>
            </div>
          </div>
          <Form className="card-components" onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col sm={12} className="mb-5 w-100">
                <Form.Group controlId="InventoryName">
                  <Form.Label className="title-text">Inventory Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Inventory Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group controlId="InventoryLocation">
                  <Form.Label className="title-text">Inventory Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Inventory Location"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Modal.Footer className="d-flex Modal-Footer">
              <Button
                className="Styled-Button Back-Button"
                variant="secondary"
                onClick={handleBackToList}
              >
                Go Back
              </Button>
              <Button
                className="Styled-Button Create-Button"
                variant="primary"
                type="submit"
              >
                Create Inventory
              </Button>
            </Modal.Footer>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default InventoryModal;
