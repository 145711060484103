import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import sidbarLogo from "../../../assets/images/ecommerce_img1.png";
import CreateProduct from "../adminDataTables/productMangment/CreateProduct";
import "./SideBarAdmin.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { logout } from "../../utils/UserUtil";
import { toast } from "sonner";
import InventoryModal from "../adminDataTables/inventoryMangment/InventoryModal";

const navItems = [
 
  { icon: "inventory_2", label: "Products", link: "/Admin/products" },
  { icon: "category", label: "Category", link: "/Admin/categories" },
  { icon: "mail", label: "Messages", link: "/Admin/ContactUs" },
];

const SideBarAdmin = () => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isProductsExpanded, setIsProductsExpanded] = useState(false);
  const [isInventoryExpanded, setIsInventoryExpanded] = useState(false);
  const [isCategoriesExpanded, setIsCategoriesExpanded] = useState(false);
  const sidebarMenuRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(sidebarMenuRef.current.scrollTop > 0);
    };

    const sidebarMenu = sidebarMenuRef.current;
    sidebarMenu.addEventListener("scroll", handleScroll);

    if (sidebarMenu.scrollHeight <= sidebarMenu.clientHeight) {
      setIsScrolled(false);
    }

    return () => {
      sidebarMenu.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavigate = (navigation) => {
    navigate(navigation);
  };

  const handleLogout = () => {
    try {
      const result = logout();
      if (result) {
        toast("Logged out successfully", {
          type: "success",
          position: "top-center",
        });
        navigate("/login")
      }
    } catch (error) {
      console.error("Error logging out:", error);
      toast("Failed to log out", { type: "error" });
    }
  };

  const handleToggleProducts = () => {
    setIsProductsExpanded(!isProductsExpanded);
  };

  const handleToggleInventory = () => {
    setIsInventoryExpanded(!isInventoryExpanded);
  };

  const handleToggleCategories = () => {
    setIsCategoriesExpanded(!isCategoriesExpanded);
  };

  const handleBackToList = () => {};

  return (
    <div className={`main-sidebar ${isOpen ? "open" : ""}`}>
      <div className="sidebar">
        <nav className={`Admin-sidebar ${isOpen ? "open" : ""}`}>
          <div className="sidebar-inner">
            <header className="sidebar-header">
              <button
                type="button"
                className="sidebar-burger"
                onClick={() => setIsOpen(!isOpen)}
              >
                <span className="material-symbols-outlined">
                  {isOpen ? "close" : "menu"}
                </span>
              </button>
              <img
                src={sidbarLogo}
                className="logo-sidebar"
                alt="Sidebar Logo"
              />
            </header>
            <nav
              ref={sidebarMenuRef}
              className={`sidebar-menu ${isScrolled ? "scrolled" : ""}`}
            >
              <div className="sidebar-content">
                {navItems.map((item, index) =>
                  item.label === "Products" ? (
                    <div key={index} className="sidebar-item">
                      <button
                        type="button"
                        className="sidebar-button"
                        onClick={handleToggleProducts}
                      >
                        <span className="material-symbols-outlined">
                          {item.icon}
                        </span>
                        <span>{item.label}</span>
                        <span className="material-symbols-outlined">
                          {isProductsExpanded
                            ? "keyboard_arrow_up"
                            : "keyboard_arrow_down"}
                        </span>
                      </button>
                      {isProductsExpanded && (
                        <div>
                          <button
                            type="button"
                            className="sidebar-button nested"
                            onClick={() => handleNavigate("/Admin/products")}
                          >
                            <span className="material-symbols-outlined">
                              view_list
                            </span>
                            <span> View All</span>
                          </button>
                          <button
                            type="button"
                            className="sidebar-button nested"
                            onClick={() => setModalShow(true)}
                          >
                            <span className="material-symbols-outlined">
                              add_circle
                            </span>
                            <span> Add Product</span>
                          </button>
                          <CreateProduct
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            onBackToList={handleBackToList}
                          />


                          <button
                            type="button"
                            className="sidebar-button nested"
                            onClick={() =>
                              handleNavigate("/Admin/ProductRecycleBin")
                            }
                          >
                            <span className="material-symbols-outlined">
                              delete
                            </span>
                            <span> RecycleBin</span>
                          </button>
                        </div>
                      )}
                    </div>
                  )  : item.label === "Category" ? (
                    <div key={index} className="sidebar-item">
                      <button
                        type="button"
                        className="sidebar-button"
                        onClick={handleToggleCategories}
                      >
                        <span className="material-symbols-outlined">
                          {item.icon}
                        </span>
                        <span>{item.label}</span>
                        <span className="material-symbols-outlined">
                          {isCategoriesExpanded
                            ? "keyboard_arrow_up"
                            : "keyboard_arrow_down"}
                        </span>
                      </button>
                      {isCategoriesExpanded && (
                        <div>
                          <button
                            type="button"
                            className="sidebar-button nested"
                            onClick={() => handleNavigate("/Admin/categories")}
                          >
                            <span className="material-symbols-outlined">
                              view_list
                            </span>
                            <span> View All</span>
                          </button>

                        </div>
                      )}
                    </div>
                  ) : (
                    <button
                      key={index}
                      type="button"
                      className="sidebar-button"
                      onClick={() => handleNavigate(item.link)}
                    >
                      <span className="material-symbols-outlined">
                        {item.icon}
                      </span>
                      <span>{item.label}</span>
                    </button>
                  )
                )}

                <button
                  type="button"
                  className={`  ${isOpen ? "logoutopen" : "logoutclose"} `}
                  onClick={handleLogout}
                >
                  <span className="material-symbols-outlined">logout</span>
                  Logout
                </button>
              </div>
            </nav>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default SideBarAdmin;
