import React, { useState, useEffect } from "react";
import { getMainCategories } from "../utils/CategoryUtil";
import { website } from "../utils/GlobalUtils";
import Carousel from "react-spring-3d-carousel";
import { config } from "@react-spring/web";
import "./CategoryCarousel.css";
import { useNavigate } from "react-router-dom";

function CategoryCarousel() {
  const navigate = useNavigate();
  const [currentItems, setCurrentItems] = useState([]);
  const [goToSlide, setGoToSlide] = useState(0);
  const [offsetRadius, setOffsetRadius] = useState(3);
  const [showNavigation, setShowNavigation] = useState(true);
  const [carouselConfig, setCarouselConfig] = useState(config.gentle);

  const fetchData = async () => {
    await getMainCategories(setCurrentItems);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log("Current Items: ", currentItems);
  }, [currentItems]);

  const handleExploreClick = (categoryId) => {
    navigate("/productList", {
      state: { categoryId: [categoryId] },
    });
  };

  const slides = currentItems.map((item, index) => ({
    key: index,
    onClick: () => setGoToSlide(index),
    content: (
      <>
        <div
          id="category-carousel-item"
          key={index}
          className={`carousel-item ${index === goToSlide ? "active" : ""}`}
          onClick={index === goToSlide ? () => handleExploreClick(item.id) : null}
        >
          <img src={`${website}${item.url}`} alt={item.name} />
        </div>
      </>
    ),
  }));

  return (
    <div className="category-carouselContainer">
      <div id="category-carousel">
        <Carousel
          slides={slides}
          goToSlide={goToSlide}
          offsetRadius={offsetRadius}
          animationConfig={carouselConfig}
        />
      </div>
      
        <div id="category-carousel-navigation">
          <button
            onClick={() => setGoToSlide((goToSlide - 1 + currentItems.length) % currentItems.length)}
            className="btn-Category left"
          >
            <span className="material-symbols-outlined">arrow_left_alt</span>
          </button>
          <button
            onClick={() => setGoToSlide((goToSlide + 1) % currentItems.length)}
            className="btn-Category right"
          >
            <span className="material-symbols-outlined">arrow_right_alt</span>
          </button>
        </div>
      
     
    </div>
  );
}

export default CategoryCarousel;
