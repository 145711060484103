import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-bootstrap/Pagination";
import { website } from "../../../utils/GlobalUtils";
import "../AdminDataTable.css";
import {
  getCategories,
  getSearchCategories,
  deleteCategory,
} from "../../../utils/CategoryUtil";


const CategoryDataTable = () => {
  const navigate = useNavigate();
  const [filterDate, setFilterDate] = useState("");
  const [subcategories, Setsubcategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isMainCategory, setIsMainCategory] = useState(0);
  const [isMain, setIsMain] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [categoriesData, setCategoriesData] = useState({ data: [], total: 0 });
  const [loading, setLoading] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const handleDeleteCategory = (id) => {
    deleteCategory(id)
      .then(() => {
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting category:", error);
      });
  };

  const handleMainCategoryChange = (e) => {
    const isChecked = e.target.checked;
    console.log(isChecked);
    setIsMain(isChecked);
    setIsMainCategory(isChecked ? 1 : 0);
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };
  const handlePerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const fetchData = () => {
    if (isMain || searchTerm) {
      getSearchCategories(
        currentPage,
        itemsPerPage,
        searchTerm,
        isMainCategory,
        setCategoriesData,
        setCurrentItems,
        setFilteredItems
      );
    } else {
      getCategories(
        currentPage,
        itemsPerPage,
        setCategoriesData,
        setLoading,
        setCurrentItems
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, searchTerm, isMain]);

  const handleEditCategory = (item) => {
    navigate("/Admin/EditCategoryModal", {
      state: { data: item },
    });

    Setsubcategories(item);
  };
  const totalItems = categoriesData.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="container-DataTable card  mt-4">
      <div className="header-DataTable">
        <div className="row w-100  d-flex align-items-center">
          <div className=" col-lg-12 text-center mb-4">
            <h1>Category List</h1>
          </div>

          <div className="col-md-3  col-6 order-1">
            <button
              className="d-flex align-items-center Button-Container"
              onClick={() => navigate("/Admin/createcategory")}
            >
              <span className="material-symbols-outlined">add_circle</span>
              <span> Create Category</span>
            </button>
          </div>
          <div className="col-md-6 justify-content-center d-flex  order-3 order-md-2 ">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => handleSearchChange(e.target.value)}
              className="search-input"
            />
          </div>
          <div className="col-md-2  col-6 d-flex order-2 order-md-3 justify-content-end">
            <button onClick={toggleDropdown} className="filter-button">
              Filters
            </button>
          </div>

          {isDropdownVisible && (
            <div className="dropdown-Filter">
              <div className="filter">
                <label>Main Category:</label>
                <input
                  type="checkbox"
                  checked={isMain}
                  onClick={handleMainCategoryChange}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <table className="custom-table">
        <thead>
          <tr>
            <th>Category Name</th>

            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.length > 0 ? (
            currentItems.map((item) => (
              <tr key={item.id}>
                <td>
                  <div className="d-flex align-items-center ">
                    <div className="image-container">
                      <img
                        src={website + item.url}
                        alt="Main Image"
                        className="imgMain"
                      />
                    </div>
                    {item.name}
                  </div>
                </td>

                <td>
                  <div className="Actions">
                    <button className="styled-button">
                      <span
                        className="material-symbols-outlined"
                        onClick={() => handleEditCategory(item)}
                      >
                        edit{" "}
                      </span>
                    </button>
                    <button className="styled-button">
                      <span
                        className="material-symbols-outlined"
                        onClick={() => handleDeleteCategory(item.id)}
                      >
                        delete
                      </span>
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No items found</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="footer-DataTable">
        <div className="per-page">
          <select value={itemsPerPage} onChange={handlePerPageChange}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
        </div>
        <Pagination className="pagination-custom">
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          <Pagination.Item disabled>
            Page {currentPage} of {totalPages}
          </Pagination.Item>
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
};
export default CategoryDataTable;
