import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProductCarsoul from "../products/ProductCarsoul";
import { useNavigate } from "react-router-dom";
import { getAllProducts } from "../utils/ProductUtil";
import "./ProductSection.css";

const ProductSection = ({ title, id }) => {
  const navigate = useNavigate();
 
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(100000);
  const [productsData, setProductsData] = useState({ data: [], total: 0 });
  const [currentItems, setCurrentItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([id]);
  const [selectedInventoryId, setSelectedInventoryId] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    getAllProducts(
      currentPage,
      itemsPerPage,
      searchTerm,
      minPrice,
      maxPrice,
      selectedItems,
      selectedInventoryId,
      setProductsData,
      setCurrentItems,
      setLoading
    );
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, searchTerm, minPrice, maxPrice, selectedInventoryId]);


  if (currentItems.length === 0) {
    return null;
  }

  return (
    <Container className="productSection-container">

      <div className="productSection-header">
        <h2 className="productSection-title">{title}</h2>
        <button
          className="productSection-button"
          onClick={() =>
            
            navigate("/productList", {
              state: { categoryId: [id] },
              
            })
          }
        >
          View All
          <span className="material-symbols-outlined productSection-button-icon">
            arrow_forward_ios
          </span>
        </button>
      </div>

      <Row className="d-flex justify-content-center align-items-center">
        <Col>
          <ProductCarsoul
            categoryId={id}
            currentItems={currentItems}
            itemsPerPage={itemsPerPage}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ProductSection;
