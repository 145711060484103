import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-bootstrap/Pagination";
import "../AdminDataTable.css";
import CreateProduct from "./CreateProduct";
import UpdateProduct from "./UpdateProduct";
import { useNavigate } from "react-router-dom";
import { website } from "../../../utils/GlobalUtils";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Select, { components } from 'react-select';

import {
  getAllProducts,
  deleteProduct,
  getProductByid,
} from "../../../utils/ProductUtil";

import {
  getAllInventories
} from "../../../utils/InventoriesUtil";



const ProductDataTable = () => {
  
  const [searchTerm, setSearchTerm] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(100000);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [productsData, setProductsData] = useState({ data: [], total: 0 });
  const [editproductData, setEditproductData] = useState({});
  const [currentItems, setCurrentItems] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowEditProduct, setModalShowEditProduct] = useState(false);
  const [productid, SetProductid] = useState(0);
  const [value, setValue] = React.useState([20, 37]);
  const [selectedItems, setSelectedItems] = useState([]);
 
  const [inventoryData, setInventotyData] = useState({ data: [], total: 0 });
  const [currentItemsInventory, setCurrentItemsInventory] = useState([]);
  const [currentPageInventory, setCurrentPageInventory] = useState(1);
  const [itemsPerPageInventory, setItemsPerPageInventory] = useState(100);
  const [selectedInventoryId, setSelectedInventoryId] = useState(0);
  const [searchTermInventory, setSearchTermInventory] = useState("");
  const [loading, setLoading] = useState(true);
  const handleChange = (event, newValue) => {
    
    setValue(newValue);
  setMinPrice(newValue[0]);
  setMaxPrice(newValue[1]);
  };

  const navigate = useNavigate();

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };
 

  const handlePerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSelectInventory = (selectedOption) => {
    setSelectedInventoryId(selectedOption.value);
    
    
}
const handleInputChange = (inputValue, { action }) => {
  if (action === "input-change") {
      console.log('Input value:', inputValue);
      setSearchTermInventory(inputValue)
  }
}


  const CustomMenu = (props) => {
    const displayedCount = Math.min(itemsPerPageInventory, totalItemsInventory);
    return (
        <components.Menu {...props}>
            {props.children}
            <div style={{ padding: '8px', textAlign: 'center',  fontWeight: '300'}}>
                {displayedCount} out of {totalItemsInventory}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }}>
               
                
            </div>
        </components.Menu>
    );
};

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleDeleteProduct = (id) => {
    deleteProduct(id)
      .then(() => {
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting product:", error);
      });
  };

  const handleEditProduct = async (id,data) => {
    navigate(`/Admin/UpdateProduct/${id}`);
  
    
  };
  const handleViewRatingProduct = (id) => {
    navigate(`/Admin/ProductReviews/${id}`);
};

  const fetchData = () => {
    getAllProducts(
      currentPage,
      itemsPerPage,
      searchTerm,
      minPrice,
      maxPrice,
      selectedItems,
      selectedInventoryId,
      setProductsData,
      setCurrentItems,
      setLoading
      
    );
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, searchTerm,minPrice, maxPrice,selectedInventoryId]);

  useEffect(() => {
    if(!modalShowEditProduct){
      fetchData();
    }
    
  }, [modalShowEditProduct]);

  

  const fetchInventoryData = () => {
    getAllInventories(
      currentPageInventory,
      itemsPerPageInventory,
      searchTermInventory,
      setInventotyData,
      setCurrentItemsInventory
    );
  };

  useEffect(() => {
    fetchInventoryData();
  }, [currentPageInventory, itemsPerPageInventory, searchTermInventory]);

  const handleBackToList = async() => {
    await fetchData();
  };

  function valuetext(value) {
    return `${value}°C`;
  }  
 
  const totalItems = productsData.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const totalItemsInventory = inventoryData.total;
  const options = [
    { value: '', label: 'Select Inventory' },
    ...currentItemsInventory.slice(0, itemsPerPageInventory).map(item => ({
        value: item.id,
        label: `${item.name} - ${item.location}`
    }))
];
  return (
    <div className="container-DataTable card  mt-4">
      <div className="row mb-3 d-flex align-items-center">
        <div className=" col-lg-12 text-center">
          <h1>Product List</h1>
        </div>

        <div className="col-md-3  col-6 order-1 ">
          <button
            className="d-flex align-items-center Button-Container"
            onClick={() => setModalShow(true)}
          >
            <span className="material-symbols-outlined">add_circle</span>
            <span> Create Product</span>
          </button>
        </div>

        <div className="col-md-6 justify-content-center d-flex  order-3 order-md-2">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => handleSearchChange(e.target.value)}
            className="search-input"
          />
        </div>

       

        <CreateProduct
          show={modalShow}
          onHide={() => setModalShow(false)}
          onBackToList={handleBackToList}
        />


        {isDropdownVisible && (
          <div className="dropdown-Filter">
            <div className="filter">
           
      <Slider
        getAriaLabel={() => 'Price range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        min={0} 
        max={100000}
        sx={{color:"#2563eb"}}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2}}>
      Min Price: {minPrice} | Max Price: {maxPrice}
      </Box>
      
      <div>
            
            <Select
                options={options}
                onChange={handleSelectInventory}
                onInputChange={handleInputChange}
                placeholder="Search Inventory"
                components={{ Menu: CustomMenu }}
                defaultValue={options[0]} 
                
            />
            
        </div>
            </div>
          </div>
        )}
      </div>
      <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
           
            <th>Product Name</th>
            <th>price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.length > 0 ? (
            currentItems.map((item) => (
              <tr key={item.id}>
                
                <td>
                  <div className="d-flex align-items-center ">
                    
                    <div className="image-container">
                      <img
                        src={website + item.main_image}
                        alt="Main Image"
                        className="imgMain"
                      />
                    </div>
                    {item.name}
                  </div>
                </td>
                <td>{item.price}</td>

                <td>
                  <div className="Actions">
                    <button className="styled-button">
                      <span
                        className="material-symbols-outlined"
                        onClick={() => handleEditProduct(item.id,item)}
                      >
                        Edit
                      </span>
                    </button>
                    <button className="styled-button">
                      <span
                        className="material-symbols-outlined delete-button"
                        onClick={() => handleDeleteProduct(item.id)}
                      >
                        delete
                      </span>
                    </button>
                    
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No items found</td>
            </tr>
          )}
        </tbody>

      </table>
      </div>
      <div className="footer-DataTable">
        <div className="per-page">
          <select value={itemsPerPage} onChange={handlePerPageChange}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
        </div>
        <Pagination className="pagination-custom">
                <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="prev"
                />
                <Pagination.Item >
                    Page {currentPage} of {totalPages}
                </Pagination.Item>
                <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="next"
                />
            </Pagination>
      </div>
    </div>
  );
};
export default ProductDataTable;
