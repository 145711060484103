import React from "react";
import { useNavigate } from "react-router-dom";
import "./ProductsCard.css";
import { website } from "../utils/GlobalUtils";

const ProductsCard = ({ id, name, main_image, price, category }) => {
  const navigate = useNavigate();

  const handleTitleClick = () => {
    navigate(`/productPage/${id}`);
    console.log(id)
  };

  return (
    <div className="product-card-ms" onClick={handleTitleClick}>
      <div className="product-image-container-ms">
        <img
          src={website + main_image}
          alt="Product Image"
          className="product-image-ms"
        />
      </div>

      <div className="product-title-ms">{name}</div>
    </div>
  );
};

export default ProductsCard;
