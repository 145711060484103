import { getCookie } from "./cookieUtils";
import { postAuth, get, getAuth, deleteAuth } from "./GlobalUtils";

export const getAllProducts = async (
  currentPage,
  itemsPerPage,
  searchTerm,
  minPrice,
  maxPrice,
  categoryIds,
  selectedInventoryId,
  setProductsData,
  setCurrentItems,
  setLoading
) => {
  try {
    let url = `/products/search/${itemsPerPage}?page=${encodeURIComponent(
      currentPage
    )}&name=${encodeURIComponent(searchTerm)}&min_price=${encodeURIComponent(
      minPrice
    )}&max_price=${encodeURIComponent(
      maxPrice
    )}&inventory_id=${encodeURIComponent(selectedInventoryId)}`;
    if (categoryIds && categoryIds.length > 0) {
     
      const categoryParams = categoryIds
        .map((id) => `category_id[]=${encodeURIComponent(id)}`)
        .join("&");
      url += `&${categoryParams}`;
      
    }
   
    const response = await get(url);
    const data = await response.json();
   
    setProductsData({
      data: data.products,
      total: data.pagination.total,
    });
    setCurrentItems(data.products);
  } catch (error) {
    console.error("Error fetching products:", error);
  } finally {
    setLoading(false);
  }
};

export const deleteProduct = async (id) => {
  try {
    await deleteAuth(`/products/${id}`);
    console.log(`Product with ID ${id} has been deleted.`);
  } catch (error) {
    console.error("Error deleting product :", error);
    throw error;
  }
};
export const forceDeleteProduct = async (id) => {
  try {
    await deleteAuth(`/products/${id}/force`);
    console.log(`Product with ID ${id} has been deleted.`);
  } catch (error) {
    console.error("Error deleting product permently:", error);
    throw error;
  }
};
export const getProductByid = async (id, setproductData, setLoading) => {
  try {
    const response = await getAuth(`/products/${id}`);
    const data = await response.json();
    setproductData(data);
    return response
  } catch (error) {
    console.error("Error fetching Product by id:", error);
  } finally {
    setLoading(false);
  }
};
export const getSoftDeleted = async (
  currentPage,
  itemsPerPage,
  setProductsData,
  setLoading,
  setCurrentItems
) => {
  try {
    const response = await getAuth(
      `/products/soft-deleted/${itemsPerPage}?page=${currentPage}`
    );
    const data = await response.json();
    setProductsData({
      data: data.data,
      total: data.total,
    });
   
    setCurrentItems(data.data);
  } catch (error) {
    console.error("Error fetching soft delete products:", error);
  } finally {
    setLoading(false);
  }
};
export const restoreProduct = async (id) => {
  try {
    const response = await postAuth(`/products/${id}/restore`, {});
    
    return response.data;
  } catch (error) {
    console.error("Error restoring Product:", error);
    throw error;
  }
};
export const createProduct = async (completeFormData) => {
  try {
    const response = await postAuth("/products", completeFormData);
    return response.data;
  } catch (error) {
    console.error("Error creating product:", error);
    throw error;
  }
};
export const updateProduct = async (id, completeFormData) => {
  try {
    const response = await postAuth(`/products/${id}`, completeFormData);
    return response.data;
  } catch (error) {
    console.error("Error updating product:", error);
    throw error;
  }
};

