import { getAuth, postAuth,LogoutAuth } from "./GlobalUtils";



export const getUsers = async (
  currentPage,
  itemsPerPage,
  setCurrentPage,
  setUsersData,
  setLoading,
  setCurrentItems
) => {
  try {
    setLoading(true);

    const response = await getAuth(
      `/admin/users/all/${itemsPerPage}?page=${currentPage}`
      
    );
    const responseData = await response.json();

    setUsersData(responseData.users);
    setCurrentItems(responseData.users.data);
    setLoading(false);
  } catch (error) {
    console.error("Error fetching users data:", error);
    setLoading(false);
  }
};

export const getSearchedUsers = async (
  currentPage,
  itemsPerPage,
  date,
  role,
  userName,
  setUsersData,
  setCurrentItems,
  setFilteredItems
) => {
  try {

    const response = await getAuth(
      `/admin/users/search/${itemsPerPage}?page=${currentPage}&created_at=${date}&role=${role}&email=&name=${userName}&email=${userName}`
    );
    const responseData = await response.json();

    setUsersData(responseData.users);
    setCurrentItems(responseData.users.data);
    setFilteredItems(responseData.users.data);
  } catch (error) {
    console.error("Error fetching users data:", error);
  }
};

export const getRoles = async () => {
  try {

    const response = await getAuth("/admin/users-roles");
    const responseData = await response.json();

    return responseData.roles;
  } catch (error) {
    console.error("Error fetching roles:", error);
    return [];
  }
};

export const logout = async () => {
 
    const result = await LogoutAuth("/users/logout", {});

      return true;
    
  
};

