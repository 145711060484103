import React,{useState,useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link,useNavigate } from "react-router-dom";
import "./ContactUs.css";
import { getMainCategories } from "../utils/CategoryUtil";

const Footer = () => {

  const [currentItems, setCurrentItems] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = () => {
    getMainCategories(
      setCurrentItems,

      
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();
  const handleNavigation = (categoryId) => {
 
    navigate("/ProductList", {
      state: { categoryId: categoryId !== undefined ? [categoryId] : [] },
    });
  };
  
  return (
    <footer className="text-center text-lg-start">
      <Container
        fluid
        style={{
          backgroundColor: "#393939",
          color: "white",
          paddingTop: "56px",
          paddingLeft: "56px",
          paddingRight: "56px",
          paddingBottom: "32px",
        }}
      >
        <Row style={{ color: "white" }}>
          <Col lg={3} md={6} className="mb-4 mb-md-0">
            <h5 className="text-uppercase">Help</h5>
            <ul className="list-unstyled mb-0">
              <li>
                <Link
                  to="/ContactUs"
                  className="text-white"
                  style={{ textDecoration: "none", cursor: "pointer" }}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </Col>
          <Col lg={3} md={6} className="mb-4 mb-md-0">
      <h5 className="text-uppercase">Our Products</h5>
      <ul className="list-unstyled mb-0">
        <li>
          <span
            onClick={() => handleNavigation()}
            className="text-white"
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            All Products
          </span>
        </li>
        {currentItems.map((item, index) => (
        <li key={index}>
          <span
            onClick={() => handleNavigation(item.id)}
            className="text-white"
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
           {item.name}
          </span>
        </li>
         ))}

      </ul>
    </Col>
          <Col lg={3} md={6} className="mb-4 mb-md-0">
            <h5 className="text-uppercase">Legal Matters</h5>
            <ul className="list-unstyled mb-0">
              <li>
                <Link
                  to="/"
                  className="text-white"
                  style={{ textDecoration: "none" }}
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className="text-white"
                  style={{ textDecoration: "none" }}
                >
                  Privacy & Policy
                </Link>
              </li>
            </ul>
          </Col>
          <Col lg={3} md={6} className="mb-4 mb-md-0">
            <div className="text-center contacts-container">
              <div className="d-flex justify-content-center gap-3">
                <FacebookIcon />
                <TwitterIcon />
                <InstagramIcon />
              </div>
              <div className="contact-info mt-3">
                <p>Phone: +961 03 644 699</p>
    
                <p>Email: contact@mdagherest.com</p>
                <p>Address: Lebanon, Haret Hreik, Abbass Moussawi Street</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div
        className="text-center p-3"
        style={{ backgroundColor: "#323232", color: "white" }}
      >
        © 2024 Copyright:
        <a
          className="text-white"
          style={{
            textDecoration: "none",
            marginLeft: "4px",
            marginRight: "4px",
          }}
          href="#"
        >
          COG Devs
        </a>
        - MDagher Establishment - All Rights Reserved
      </div>
    </footer>
  );
};

export default Footer;
