import React, { useEffect, useState } from "react";

import Hero from "./hero/Hero";
import MainSection from "./mainSection/MainSection";
import ProductSection from "./mainSection/ProductSection";
import { getMainCategories } from "./utils/CategoryUtil";
import ParallaxSection from "./mainSection/ParallaxSection";
import img1 from "../assets/images/Parallex.png";
import "./LandingPage.css";
import { Container } from "react-bootstrap";
import CategoryCarousel from "./mainSection/CategoryCarousel";


const LandingPage = () => {
  const [currentItems, setCurrentItems] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = () => {
    getMainCategories(setCurrentItems);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Hero />

      <ParallaxSection
        className="parallax-section"
        bgImage={img1}
        children={
          <>
            {" "}
            <h3 className="Main__category--title">Mission and Vision</h3>
            <p className="parallex-about-p">
              {" "}
              Founded in 2007 by Mahdi Dagher in Beirut, Lebanon, Mahdi Dagher Est. is dedicated to producing and distributing high-quality socks, plastic bags, and tissues. Our mission is to exceed client expectations with exceptional products tailored to their needs. We strive to be recognized globally for our commitment to quality, innovation, and customer satisfaction in the fashion and consumer goods sectors.
            </p>
          </>
        }
      ></ParallaxSection>

      <CategoryCarousel />




      {currentItems.map((item, index) => (
        <ProductSection key={index} title={item.name} id={item.id} />
      ))}


    </>
  );
};

export default LandingPage;
