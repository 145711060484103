import { postAuth,getAuth } from "./GlobalUtils";

export const AddContact = async (first_name, last_name, email, message) => {
  try {
    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("message", message);

    const response = await postAuth(
      "/contacts",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("Message Sent");
  } catch (error) {
    console.error("Error Contacting:", error);
    throw error;
  }
};
export const getContacts = async (
  setCurrentItems,
) => {
  try {
    const response = await getAuth(
      `/contacts`,
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
  
    setCurrentItems(data);
  } catch (error) {
    console.error("Error fetching categories:", error);
  }
};