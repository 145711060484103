import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import ProductDropdown from "./ProductDropdown";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import "./ProductImagePreview.css";

import { Navigation } from "swiper/modules";
import "./AddProductByUrl.css";
const AddProductByUrl = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: 0,
    category_ids: [],
  });
  const [files, setFiles] = useState({ main_image: null, images: [] });
  const [selectedItems, setSelectedItems] = useState([]);
  const [imagePreviews, setImagePreviews] = useState({
    main_image: null,
    images: [],
  });
  const [inventoires, setinventoires] = useState([]);
  const [countImages, setCountImages] = useState(0);

  const mainImageRef = useRef(null);
  const additionalImagesRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleSelectedItemsChange = (items) => {
    setSelectedItems(items);
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    const filteredFiles = Array.from(files).filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return ["jpg", "png", "jpeg"].includes(extension);
    });

    if (filteredFiles.length === 0) {
      console.log("No valid files selected.");
      return;
    }

    if (name === "main_image") {
      const file = filteredFiles[0];
      setFiles((prevFiles) => ({
        ...prevFiles,
        main_image: file,
      }));
      setImagePreviews((prevPreviews) => ({
        ...prevPreviews,
        main_image: URL.createObjectURL(file),
      }));
      setCountImages(1);
    } else {
      const newImages = filteredFiles;
      setFiles((prevFiles) => ({
        ...prevFiles,
        images: [...prevFiles.images, ...newImages],
      }));
      setImagePreviews((prevPreviews) => ({
        ...prevPreviews,
        images: [
          ...prevPreviews.images,
          ...newImages.map((file) => URL.createObjectURL(file)),
        ],
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "price" ? parseFloat(value) : value,
    }));
  };

  const handleRemoveImage = (type, index) => {
    if (type === "main_image") {
      setFiles((prevFiles) => ({ ...prevFiles, main_image: null }));
      setImagePreviews((prevPreviews) => ({
        ...prevPreviews,
        main_image: null,
      }));
      setCountImages(0);
    } else {
      const newImages = files.images.filter((_, i) => i !== index);
      const newImagePreviews = imagePreviews.images.filter(
        (_, i) => i !== index
      );
      setFiles((prevFiles) => ({ ...prevFiles, images: newImages }));
      setImagePreviews((prevPreviews) => ({
        ...prevPreviews,
        images: newImagePreviews,
      }));
      console.log(files.images.length - 1);
      additionalImagesRef.current.value = null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const completeFormData = new FormData();
    completeFormData.append("name", formData.name);
    completeFormData.append("description", formData.description);
    completeFormData.append("price", formData.price);
    selectedItems.forEach((id) => {
      completeFormData.append(`category_ids[]`, id);
    });
    if (files.main_image) {
      completeFormData.append("main_image", files.main_image);
    }
    files.images.forEach((image) => {
      completeFormData.append(`images[]`, image);
    });

    try {
      const response = await axios.post(
        "https://shopbackend.cogdevelopers.com/api/products",
        completeFormData,
        {
          headers: {
            Authorization: `Bearer ${"xbCyWt94FvO9n4x5p9Bjm9899Ipmfc6NfbPCrde84ec83ea1"}`,
          },
        }
      );
      console.log(response);
      alert("Product created successfully!");
      dropdownRef.current.clearSelectedItems();
    } catch (error) {
      console.error(error);
      alert("An error occurred while creating the product.");
      dropdownRef.current.clearSelectedItems();
    }

    setFormData({
      name: "",
      description: "",
      price: 0,
      category_ids: [],
    });

    setFiles({ main_image: null, images: [] });
    setImagePreviews({ main_image: null, images: [] });

    mainImageRef.current.value = null;
    additionalImagesRef.current.value = null;
  };

  const handleBackToList = () => {
    props.onBackToList();
    props.onHide();
  };

  return (
    <Container fluid >
      <div className="card-header bg-secondary bg-gradient ml-0 py-3 mb-4">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="text-white"> Create Product </h1>
          </div>
        </div>
      </div>
      <Form className="card-components" onSubmit={handleSubmit}>
        <Row className="mb-3 " >
          <Col lg={12} className="d-flex  flex-column justify-content-center align-items-center">
            <div className="radio-inputs mb-4" >
              <label className="radio">
                <input type="radio" name="radio"></input>
                <span className="name">Amazon</span>
              </label>
              <label className="radio">
                <input type="radio" name="radio"></input>
                <span className="name">Ebay</span>
              </label>

              <label className="radio">
                <input type="radio" name="radio"></input>
                <span className="name">Shein</span>
              </label>
              <label className="radio">
                <input type="radio" name="radio"></input>
                <span className="name">Ali Baba</span>
              </label>
              <label className="radio">
                <input type="radio" name="radio"></input>
                <span className="name">Ali Express</span>
              </label>
              <label className="radio">
                <input type="radio" name="radio"></input>
                <span className="name">CJ Dropshipping</span>
              </label>
            </div>

            <div >
    <input type="email" className="url-input" id="Email" name="Email" placeholder="uiverse@verse.io" autocomplete="off"></input>
    <input className="button--submit" value="Add" type="submit"></input>
</div>

          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default AddProductByUrl;
