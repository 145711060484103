import React, { useState, useRef,useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import ProductDropdown from "./ProductDropdown";
import {createProduct} from "../../../utils/ProductUtil"
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import "./ProductImagePreview.css";

import { Navigation } from "swiper/modules";

function CreateProduct(props) {



  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: 0,
    category_ids: [],
  });
  const [files, setFiles] = useState({ main_image: null, images: [] });
  const [selectedItems, setSelectedItems] = useState([]);
  const [imagePreviews, setImagePreviews] = useState({
    main_image: null,
    images: [],
  });
  const [inventoires, setinventoires] = useState([]);
  const [countImages, setCountImages] = useState(0);

  const mainImageRef = useRef(null);
  const additionalImagesRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleSelectedItemsChange = (items) => {
    setSelectedItems(items);
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    const filteredFiles = Array.from(files).filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return ["jpg", "png", "jpeg"].includes(extension);
    });

    if (filteredFiles.length === 0) {
      console.log("No valid files selected.");
      return;
    }

    if (name === "main_image") {
      const file = filteredFiles[0];
      setFiles((prevFiles) => ({
        ...prevFiles,
        main_image: file,
      }));
      setImagePreviews((prevPreviews) => ({
        ...prevPreviews,
        main_image: URL.createObjectURL(file),
      }));
      setCountImages(1);
    } else {
      const newImages = filteredFiles;
      setFiles((prevFiles) => ({
        ...prevFiles,
        images: [...prevFiles.images, ...newImages],
      }));
      setImagePreviews((prevPreviews) => ({
        ...prevPreviews,
        images: [
          ...prevPreviews.images,
          ...newImages.map((file) => URL.createObjectURL(file)),
        ],
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "price" ? parseFloat(value) : value,
    }));
  };

  const handleRemoveImage = (type, index) => {
    if (type === "main_image") {
      setFiles((prevFiles) => ({ ...prevFiles, main_image: null }));
      setImagePreviews((prevPreviews) => ({
        ...prevPreviews,
        main_image: null,
      }));
      setCountImages(0);
    } else {
      const newImages = files.images.filter((_, i) => i !== index);
      const newImagePreviews = imagePreviews.images.filter(
        (_, i) => i !== index
      );
      setFiles((prevFiles) => ({ ...prevFiles, images: newImages }));
      setImagePreviews((prevPreviews) => ({
        ...prevPreviews,
        images: newImagePreviews,
      }));
      console.log(files.images.length - 1);
      additionalImagesRef.current.value = null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const completeFormData = new FormData();
    completeFormData.append("name", formData.name);
    completeFormData.append("description", formData.description);
    completeFormData.append("price", formData.price);
    selectedItems.forEach((id) => {
      completeFormData.append(`category_ids[]`, id);
    });
    if (files.main_image) {
      completeFormData.append("main_image", files.main_image);
    }
    files.images.forEach((image) => {
      completeFormData.append(`images[]`, image);
    });

    try {
      const response = await createProduct(completeFormData);
      console.log(response);
  
      alert("Product created successfully!");
      dropdownRef.current.clearSelectedItems();
    } catch (error) {
      console.error(error);
      alert("An error occurred while creating the product.");
      dropdownRef.current.clearSelectedItems();
    }

    setFormData({
      name: "",
      description: "",
      price: 0,
      category_ids: [],
    });

    setFiles({ main_image: null, images: [] });
    setImagePreviews({ main_image: null, images: [] });

    mainImageRef.current.value = null;
    additionalImagesRef.current.value = null;
  };

  const handleBackToList = () => {
    props.onBackToList();
    props.onHide();
  };


 
  

  return (
    <Modal {...props} centered fullscreen={true} scrollable={true}>
      <Modal.Body>
        <Container className="card shadow border-0 modal-container">
          <div className="card-header bg-secondary bg-gradient ml-0 py-3 mb-4">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="text-white"> Create Product </h1>
              </div>
            </div>
          </div>
          <Form className="card-components" onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col lg={6}>
                <Form.Group controlId="productName">
                  <Form.Label className="title-text">Product Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Product Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <ProductDropdown
                  ref={dropdownRef}
                  onSelectedItemsChange={handleSelectedItemsChange}
                  CreateProduct={true}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              
              <Col lg={12}>
                <Form.Group controlId="productDescription">
                  <Form.Label className="title-text">
                    Product Description
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter Product Description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col lg={6} className="mt-2 ">
                <Form.Group className="">
                  <Form.Label className="title-text">Main Images</Form.Label>
                  <Form.Label
                    htmlFor="images"
                    className="custom-file-label mt-4 me-2 ms-2"
                  >
                    {files.main_image
                      ? files.main_image.name
                      : "No image chosen"}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="main_image"
                    ref={mainImageRef}
                    onChange={handleFileChange}
                    onInvalid={() =>
                      alert(
                        "Please select files with extensions .jpg, .png, or .jpeg"
                      )
                    }
                    accept=".jpg, .jpeg, .png"
                    className="custom-file-input"
                    id="images"
                  />
                </Form.Group>
              </Col>
              <Col lg={6} className="mt-2 ">
                <Form.Group className="">
                  <Form.Label className="title-text">
                    Additional Images
                  </Form.Label>
                  <Form.Label
                    htmlFor="Addtionalimages"
                    className="custom-file-label mt-4 me-2"
                  >
                    {files.images.length > 0
                      ? `${files.images.length} images`
                      : "No image  chosen"}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="images"
                    multiple
                    ref={additionalImagesRef}
                    onChange={handleFileChange}
                    onInvalid={() =>
                      alert(
                        "Please select files with extensions .jpg, .png, or .jpeg"
                      )
                    }
                    accept=".jpg, .jpeg, .png"
                    className="custom-file-input"
                    id="Addtionalimages"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3 no-margin">
              <Col lg={6}>
                {imagePreviews.main_image && (
                  <div
                    className="image-preview"
                    style={{
                      position: "relative",
                      width: "300px",
                      height: "300PX",
                    }}
                  >
                    <img
                      src={imagePreviews.main_image}
                      alt="Main Preview"
                      style={{ width: "100%", height: "auto" }}
                    />
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleRemoveImage("main_image")}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                )}
              </Col>
              <Col lg={6}>
                {imagePreviews.images.length > 0 && (
                  <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiperProduct"
                  >
                    {imagePreviews.images.map((image, index) => (
                      <SwiperSlide key={index} className="SwiperSlideProduct">
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "300px",
                          }}
                        >
                          <img
                            src={image}
                            alt={`Preview ${index}`}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleRemoveImage("images", index)}
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </Col>
            </Row>
            <Modal.Footer className="d-flex Modal-Footer">
              <Button
                className="Styled-Button Back-Button"
                variant="secondary"
                onClick={handleBackToList}
              >
                Go Back
              </Button>
              <Button
                className="Styled-Button Create-Button"
                variant="primary"
                type="submit"
              >
                Create Product
              </Button>
            </Modal.Footer>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default CreateProduct;
