import React from "react";
import { Parallax } from "react-parallax";

const ParallaxSection = ({ bgImage, children }) => {
  return (
    // <Container>
      <Parallax bgImage={bgImage} strength={500} className="parallax-section">
        <div style={{ position: "relative", minHeight:"500px" ,height:"100%" , display: "flex",
              alignItems: "center",
              justifyContent: "center",}}>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              zIndex: 1,
            }}
          ></div>
          <div
            style={{
              position: "relative",
              zIndex: 2, // Ensure children are above the overlay
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              color: "white",
            }}
          >
            {children}
          </div>
        </div>
      </Parallax>
    // </Container>
  );
};

export default ParallaxSection;
