import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import img1 from "../../assets/images/socks.jpg";
import img2 from "../../assets/images/backtrash2.webp";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import "./Hero.css";

export default function Hero() {
  const navigate = useNavigate();

  const slideData = [
    {
      imgSrc: img2,
      title: "Eco-Friendly Solutions",
      subText:
        "Explore our range of eco-friendly plastic bags, crafted to be strong and reusable. Support the environment.",
      buttonText: "REQUEST INQUIRY",
    },
    {
      imgSrc: img1,
      title: "Elevate Your Style",
      subText:
        "Step up your fashion game with our stylish and high-quality socks, offering unmatched comfort and trendy designs. Perfect for any occasion, they blend fashion and functionality seamlessly.",
      buttonText: "REQUEST INQUIRY",
    },
  ];

  return (
    <Container>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
        loop={true}
        className="main-hero-ms"
      >
        {slideData.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="carousel-slide">
              <div className="black-overlay-im-carousel"></div>
              <img
                src={slide.imgSrc}
                alt={`Slide ${index + 1}`}
                className="image-carousel"
              />
              <div className="hero-container">
                <div className="hero-caption">
                  <h1 className="heading-hero">{slide.title}</h1>
                  <p className="subText-hero">{slide.subText}</p>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/contactUs`);
                    }}
                    className="hero-button"
                  >
                    {slide.buttonText}
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
}
