import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { PieChart, Pie, Tooltip, Cell, Legend, LineChart, Line, XAxis, YAxis, CartesianGrid, Brush } from 'recharts';


const salesData = [
    { month: "January", Mobiles: 200, Laptops: 400, Tablets: 150 },
    { month: "February", Mobiles: 300, Laptops: 300, Tablets: 200 },
    { month: "March", Mobiles: 250, Laptops: 350, Tablets: 225 },
    { month: "April", Mobiles: 320, Laptops: 330, Tablets: 210 },
    { month: "May", Mobiles: 500, Laptops: 450, Tablets: 300 },
    { month: "June", Mobiles: 450, Laptops: 500, Tablets: 350 },
    { month: "July", Mobiles: 430, Laptops: 410, Tablets: 320 },
    { month: "August", Mobiles: 400, Laptops: 390, Tablets: 310 },
    { month: "September", Mobiles: 450, Laptops: 420, Tablets: 330 },
    { month: "October", Mobiles: 420, Laptops: 450, Tablets: 300 },
    { month: "November", Mobiles: 380, Laptops: 300, Tablets: 250 },
    { month: "December", Mobiles: 360, Laptops: 350, Tablets: 280 }
  ];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#845EC2', '#D65DB1', '#FF6F91', '#FF9671', '#FFC75F', '#F9F871'];

const CategoryChart = () => {
  const pieData = mainCategory.map(category => ({
    name: category.categoryname,
    value: Math.floor(Math.random() * 1000) + 1
  }));

  return (
    <Row>
      <Col lg="6" style={{ borderRadius: "15px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)", width: "600px", marginLeft: "70px", marginTop: "20px" }}>
        <LineChart
          width={570}
          height={300}
          data={salesData}
          margin={{ top: 10, right: 70, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="Mobiles" stroke="#8884d8" />
          <Line type="monotone" dataKey="Laptops" stroke="#00C49F" />
          <Line type="monotone" dataKey="Tablets" stroke="#FFBB28" />
          <Brush dataKey="month" height={30} stroke="#8884d8" />
        </LineChart>
      </Col>
      <Col lg="6" style={{ borderRadius: "15px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)", width: "500px", marginLeft: "70px", marginTop: "20px" }}>
        <PieChart width={500} height={360}>
          <Pie
            data={pieData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
          >
            {pieData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip formatter={(value) => [`${value} Sales`]} />
          <Legend verticalAlign="bottom" height={50}/>
        </PieChart>
      </Col>
    </Row>
  );
};

export default CategoryChart;




const mainCategory = [
    {
    categoryname: "Electronic",
    subcategories: [
    { subcategoryname: "Mobiles" },
    { subcategoryname: "Laptops", subcategories: [{ subcategoryname: "dell" }, { subcategoryname: "Mac" }] },
    { subcategoryname: "Tablets" },
    ],
    },
    {
    categoryname: "Foods",
    subcategories: [
    { subcategoryname: "Snacks" },
    { subcategoryname: "Beverages" },
    { subcategoryname: "Canned Foods" },
    ],
    },
    {
    categoryname: "Clothing",
    subcategories: [
    { subcategoryname: "Men's Clothing" },
    { subcategoryname: "Women's Clothing" },
    { subcategoryname: "Children's Clothing" },
    ],
    },
    {
    categoryname: "Books",
    subcategories: [
    { subcategoryname: "Fiction" },
    { subcategoryname: "Non-Fiction" },
    { subcategoryname: "Children's Books" },
    ],
    },
    {
    categoryname: "Furniture",
    subcategories: [
    { subcategoryname: "Living Room" },
    { subcategoryname: "Bedroom" },
    { subcategoryname: "Kitchen" },
    ],
    },
    {
    categoryname: "Toys",
    subcategories: [
    { subcategoryname: "Action Figures" },
    { subcategoryname: "Dolls" },
    { subcategoryname: "Educational Toys" },
    ],
    },
    {
    categoryname: "Sports",
    subcategories: [
    { subcategoryname: "Outdoor" },
    { subcategoryname: "Indoor" },
    { subcategoryname: "Water Sports" },
    ],
    },
    {
    categoryname: "Health",
    subcategories: [
    { subcategoryname: "Vitamins" },
    { subcategoryname: "Supplements" },
    { subcategoryname: "First Aid" },
    ],
    },
    {
    categoryname: "Beauty",
    subcategories: [
    { subcategoryname: "Skincare" },
    { subcategoryname: "Makeup" },
    { subcategoryname: "Fragrances" },
    ],
    },
    {
    categoryname: "Automotive",
    subcategories: [
    { subcategoryname: "Car Accessories" },
    { subcategoryname: "Motorcycle Accessories" },
    { subcategoryname: "Tools" },
    ],
    },
    ];