import React,{useEffect} from "react";
import "./AboutSection.css";
import AboutUs from "./../../assets/images/About-us.png"
import { Container } from "react-bootstrap";

const AboutSection = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container id="About-Us" className="about-section">
        <div className="row about__us--row">
          <div className="content-column col-md-6 col-sm-12 col-xs-12">
            <div className="inner-column">
              <div className="sec-title">
                <div className="title">About Us</div>
                <h2>We are creative fashion enthusiasts working since 2007</h2>
              </div>
              <div className="text">
                Mahdi Dagher establishment launched in Beirut, Lebanon in 2007. Its founder Mahdi Dagher is the president, stakeholder in the group. Mahdi Dagher est. has been dedicated to the market as an effective company in production and distribution of high-end fashion in leg wear and tights. Our mission at Mahdi Dagher est. is to ensure excellence in the creation, and production for exclusive brands on the client's needs and demands.
              </div>
              <a href="/ContactUs" className="theme-btn btn-style-three">Contact Us</a>
            </div>
          </div>
          <div className="image-column col-md-6 col-sm-12 col-xs-12">
            <div className="inner-column" data-wow-delay="0ms" data-wow-duration="1500ms">
              <div className="image">
                <img src={AboutUs} alt="" />
                <div className="overlay-box">
                  <div className="year-box">
                    <span className="number">17</span>
                    Years <br /> Of <br /> Experience
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </Container>
  );
};

export default AboutSection;
