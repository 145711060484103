import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./ProductSkeleton.css"; // Import the CSS file

const ProductSkeleton = () => {
  return (
    <div className="product-skeleton">
      {/* Adjust width to match your product cards */}
      <Skeleton className="skeleton-image"  />
      {/* Adjust height according to your image size */}
      <Skeleton className="skeleton-text" />
      {/* Name of the product */}
    </div>
  );
};

const ProductsListSkeleton = () => {
  return (
    <>
      {Array.from({ length: 8 }).map((_, index) => (
        <ProductSkeleton key={index} />
      ))}
    </>
  );
};

export default ProductsListSkeleton;
