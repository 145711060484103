import { get, getAuth, postAuth ,deleteAuth,putAuth} from "./GlobalUtils";
import { getCookie } from "./cookieUtils";

export const getAllInventories = async (
  currentPage,
  itemsPerPage,
  searchTerm,
  setInventotyData,
  setCurrentItems
) => {
  try {
    const accessToken = getCookie("access_token");
    const response = await getAuth(
      `/inventories/${itemsPerPage}?page=${currentPage}&search=${searchTerm}`,accessToken
    );
    const data = await response.json();
    setInventotyData({
      data: data.inventories,
      total: data.pagination.total,
    });
    setCurrentItems(data.inventories);
  } catch (error) {
    console.error("Error fetching searchProducts:", error);
  }
};

export const getInventoryByid = async (id, setEditinventoryData) => {
  try {
    const response = await getAuth(`/inventories/show/${id}`);
    const data = await response.json();
    setEditinventoryData(data);
    
   
  } catch (error) {
    console.error("Error fetching Product by id:", error);
  }
};

export const createInventory = async (name, location) => {
  try {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('location', location);

    const response = await postAuth("/inventories", formData);
    return response.data;
  } catch (error) {
    console.error("Error creating Inventory:", error);
    throw error;
  }
};

export const createInventoryProduct = async (inventory_id, products) => {
  try {
    const accessToken = getCookie("access_token");
    const response = await postAuth(
      "/inventory/add/products",
      { inventory_id, products },
      accessToken
    );
    return response.data.category;
  } catch (error) {
    console.error("Error creating category:", error);
    throw error;
  }
};


export const deleteInventory = async (id) => {
  try {
    const accessToken = getCookie("access_token");
    await deleteAuth(`/inventories/${id}`, accessToken);

    console.log(`Product with ID ${id} has been deleted.`);
  } catch (error) {
    console.error("Error deleting product :", error);
    throw error;
  }
};

export const forceDeleteInventory = async (id) => {
  try {
    const accessToken = getCookie("access_token");
    await deleteAuth(`/inventories/${id}/force-delete`, accessToken);

    console.log(`Product with ID ${id} has been deleted.`);
  } catch (error) {
    console.error("Error deleting product permently:", error);
    throw error;
  }
}; 

export const getSoftDeletedInventory = async (
  currentPage,
  itemsPerPage,
  searchTerm,
  setInventotyData,
  setLoading,
  setCurrentItems
) => {
  try {
    const accessToken = getCookie("access_token");
    const response = await getAuth(
      `/inventories/trashed/${itemsPerPage}?page=${currentPage}&search=${searchTerm}`,
      accessToken
    );
    const data = await response.json();

    setInventotyData({
      data: data.inventories,
      total: data.pagination.total,
    });
   
    setCurrentItems(data.inventories);
  } catch (error) {
    console.error("Error fetching soft delete products:", error);
  } finally {
    setLoading(false);
  }
};

export const restoreInventory = async (id) => {
  try {
    const accessToken = getCookie("access_token");
    const response = await postAuth(`/inventories/${id}/restore`, {}, accessToken);
    console.log("restored");
    return response.data;
  } catch (error) {
    console.error("Error restoring Product:", error);
    throw error;
  }
};

export const updateInventory = async (id,name, location) => {
  try {
    const accessToken = getCookie("access_token");
    const response = await putAuth(
      `/inventories/${id}`,
      { name, location },
      accessToken
    );
    console.log(`done`);

    return response;
  } catch (error) {
    console.error("Error in set other parents:", error);
    throw error;
  }
};


