import { getAuth, postAuth,get } from "./GlobalUtils";



  export const getReviews = async (
    currentPage,
    itemsPerPage,
    productid,
    setCurrentPage,
    setReviewsData,
    setLoading,
    setCurrentItems
  ) => {
    try {
      const response = await getAuth(
          `/products/reviews/${productid}?count=${itemsPerPage}&page=${currentPage}`
        
      );
      const data = await response.json();
  
      setReviewsData({
        data: data.reviews,
        total: data.pagination.total,
      });
      setCurrentItems(data.reviews);
    } catch (error) {
      console.error("Error fetching soft delete products:", error);
    } finally {
      setLoading(false);
    }
  };