import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-bootstrap/Pagination";
import "../AdminDataTable.css";
import { getUsers, getSearchedUsers, getRoles } from "../../../utils/UserUtil";
const UsersDataTables = () => {
  const [filterDate, setFilterDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState("All");
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [roles, setRoles] = useState([]);
  const [usersData, setUsersData] = useState({ data: [], total: 0 });
  const [loading, setLoading] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [filterdItems, setfilterdItems] = useState([]);

  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    setFilterDate(formattedDate);
  };
  const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };
  const handleRoleChange = (e) => {
    const role = e.target.value;
    setSelectedRole(role);
  };
  const handlePerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };
  const fetchData = () => {
    if (selectedRole !== "All" || searchTerm || filterDate) {
      getSearchedUsers(
        currentPage,
        itemsPerPage,
        filterDate,
        selectedRole !== "All" ? selectedRole : "",
        searchTerm,
        setUsersData,
        setLoading,
        setCurrentItems,
        setfilterdItems
      );
    } else {
      getUsers(
        currentPage,
        itemsPerPage,
        setCurrentPage,
        setUsersData,
        setLoading,
        setCurrentItems
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, selectedRole, searchTerm, filterDate]);
  useEffect(() => {
    getRoles().then((roles) => {
      setRoles(roles);
    });
  }, []);

  const totalItems = usersData && usersData.total ? usersData.total : 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="container-DataTable card  mt-4">
      <div className="row header-DataTable">
        <div className="col-md-3  col-6 order-1 ">
          <h1>Users List</h1>
        </div>
        <div className="col-md-6 justify-content-center d-flex  order-3 order-md-2">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => handleSearchChange(e.target.value)}
            className="search-input"
          />
        </div>
        <div className="col-md-2  col-6 d-flex order-2 order-md-3 justify-content-end ">
          <button onClick={toggleDropdown} className="filter-button">
            Filters
          </button>
        </div>
        {isDropdownVisible && (
          <div className="dropdown-Filter">
            <div className="filter">
              <div className="mb-2">
                <span style={{ marginRight: "8px" }}>Date:</span>
                <DatePicker
                  selected={filterDate ? new Date(filterDate) : null}
                  onChange={(date) => handleDateChange(date)}
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div>
                <span style={{ marginRight: "8px" }}>Role:</span>
                <select
                  value={selectedRole}
                  onChange={handleRoleChange}
                  className="w-75"
                >
                  <option value="All">All</option>
                  {roles.map((role) => (
                    <option key={role.id} value={role.name}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
      </div>
      <table className="custom-table">
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Email</th>

            <th>Registered</th>
          </tr>
        </thead>
        <tbody>
          {currentItems && currentItems.length > 0 ? (
            currentItems.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.email}</td>

                <td>{item.created_at.split("T")[0]}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No items found</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="footer-DataTable">
        <div className="per-page">
          <select value={itemsPerPage} onChange={handlePerPageChange}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
        </div>
        <Pagination className="pagination-custom">
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          <Pagination.Item disabled>
            Page {currentPage} of {totalPages}
          </Pagination.Item>
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
};
export default UsersDataTables;
