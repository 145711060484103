import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-bootstrap/Pagination";
import "../AdminDataTable.css";



import { useNavigate } from "react-router-dom";
import { website } from "../../../utils/GlobalUtils";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {getAllInventories,deleteInventory,getInventoryByid} from '../../../utils/InventoriesUtil'
import InventoryModal from "./InventoryModal";
import UpdateInventory from "./UpdateInventory";
import {
 
  deleteProduct,
  getProductByid,
} from "../../../utils/ProductUtil";
import AddInventoryProduct from "./AddInventoryProduct";


const InventoriesDataTable = () => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [inventoryData, setInventotyData] = useState({ data: [], total: 0 });
  const [editinventoryData, setEditinventoryData] = useState({});
  const [currentItems, setCurrentItems] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowEditProduct, setModalShowEditProduct] = useState(false);
  const [inventoryproductShow, setInventoryProductShow] = useState(false);
  const [productid, SetProductid] = useState(0);
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    
    setValue(newValue);
  setMinPrice(newValue[0]);
  setMaxPrice(newValue[1]);
  };

  const navigate = useNavigate();

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };


  const handlePerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleDeleteProduct = (id) => {
    deleteInventory(id)
      .then(() => {
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting Inventory:", error);
      });
  };

  const handleEditInventory = (id) => {
    getInventoryByid(id, setEditinventoryData);
    setModalShowEditProduct(true);
  };
  const handleAddInventoryProduct= (id) => {
    getInventoryByid(id, setEditinventoryData);
    setInventoryProductShow(true);
  };

  const fetchData = () => {
    getAllInventories(
      currentPage,
      itemsPerPage,
      searchTerm,
      setInventotyData,
      setCurrentItems
    );
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, searchTerm,minPrice, maxPrice]);

  const handleBackToList = () => {
    fetchData();
  };

  function valuetext(value) {
    return `${value}°C`;
  }  

  const totalItems = inventoryData.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  return (
    <div className="container-DataTable card  mt-4">
      <div className="row mb-3 d-flex align-items-center">
        <div className=" col-lg-12 text-center">
          <h1>Inventory List</h1>
        </div>

        <div className="col-md-3  col-6 order-1 ">
          <button
            className="d-flex align-items-center Button-Container"
            onClick={() => setModalShow(true)}
          >
            <span className="material-symbols-outlined">add_circle</span>
            <span> Inventory Product</span>
          </button>
        </div>

        <div className="col-md-6 justify-content-center d-flex  order-3 order-md-2">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => handleSearchChange(e.target.value)}
            className="search-input"
          />
        </div>

        <div className="col-md-2  col-6 d-flex order-2 order-md-3 justify-content-end ">
          <button onClick={toggleDropdown} className="filter-button">
            Filters
          </button>
          
        </div>

        <InventoryModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          onBackToList={handleBackToList}
        />

        <UpdateInventory
          show={modalShowEditProduct}
          onHide={() => setModalShowEditProduct(false)}
          onBackToList={handleBackToList}
          editinventoryData={editinventoryData}
        />
        <AddInventoryProduct
          show={inventoryproductShow}
          onHide={() => setInventoryProductShow(false)}
          editinventoryData={editinventoryData}
         
        />
        {isDropdownVisible && (
          <div className="dropdown-Filter ">
            <div className="filter">
           
      <Slider
        getAriaLabel={() => 'Price range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        min={0} 
        max={10000}
        sx={{color:"black"}}
      />
      <Box sx={{ mt: 2 }}>
      Min Price: {minPrice} | Max Price: {maxPrice}
      </Box>
    
            </div>
          </div>
        )}
      </div>
      <table className="custom-table">
        <thead>
          <tr>
           
            <th>Inventory Name</th>
            <th>Location</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.length > 0 ? (
            currentItems.map((item) => (
              <tr key={item.id}>
                
                <td>
                  <div className="d-flex align-items-center ">
                    
                    {item.name}
                  </div>
                </td>
                <td>{item.location}</td>

                <td>
                  <div className="Actions">
                    <button className="styled-button">
                      <span
                        className="material-symbols-outlined"
                        onClick={() => handleEditInventory(item.id)}
                      >
                        Edit
                      </span>
                    </button>
                    <button className="styled-button">
                      <span
                        className="material-symbols-outlined delete-button"
                        onClick={() => handleDeleteProduct(item.id)}
                      >
                        delete
                      </span>
                    </button>
                    <button className="styled-button">
                      <span
                        className="material-symbols-outlined edit-button"
                        
                        onClick={() => handleAddInventoryProduct(item.id)}
                      >
                        add
                      </span>
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No items found</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="footer-DataTable">
        <div className="per-page">
          <select value={itemsPerPage} onChange={handlePerPageChange}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
        </div>
        <Pagination className="pagination-custom">
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          <Pagination.Item disabled>
            Page {currentPage} of {totalPages}
          </Pagination.Item>
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
};


export default InventoriesDataTable
