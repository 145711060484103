import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-bootstrap/Pagination";
import "../AdminDataTable.css";
import CreateProduct from "./CreateProduct";
import UpdateProduct from "./UpdateProduct";
import { useNavigate } from "react-router-dom";

import {
  getSoftDeleted,
  forceDeleteProduct,
  restoreProduct,
} from "../../../utils/ProductUtil";
const ProductRecycleBin = () => {
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [productsData, setProductsData] = useState({ data: [], total: 0 });
  const [editproductData, setEditproductData] = useState({});
  const [currentItems, setCurrentItems] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowEditProduct, setModalShowEditProduct] = useState(false);
  const [productid, SetProductid] = useState(0);

  const navigate = useNavigate();

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };
  const handlePerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleDeleteProduct = (id) => {
    forceDeleteProduct(id)
      .then(() => {
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting product:", error);
      });
  };

  const handleRetriveProduct = (id) => {
    restoreProduct(id)
      .then(() => {
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting product:", error);
      });
  };

  const fetchData = () => {

      getSoftDeleted(
        currentPage,
        itemsPerPage,
        setProductsData,
        setLoading,
        setCurrentItems
      );
    
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage]);

  const handleBackToList = () => {
    fetchData();
  };

  console.log(currentItems)
  const totalItems = productsData.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  return (
    <div className="container-DataTable card  mt-4">
      <div className="row mb-3 d-flex align-items-center">
        <div className=" col-lg-12 text-center">
          <h1>Retrieve Products</h1>
        </div>

        <div className="col-3"></div>

        <div className="col-6 justify-content-center d-flex">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => handleSearchChange(e.target.value)}
            className="search-input"
          />
        </div>

     




        {isDropdownVisible && (
          <div className="dropdown-Filter">
            <div className="filter"></div>
          </div>
        )}
      </div>
      <table className="custom-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Product Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.length > 0 ? (
            currentItems.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td className="Actions">
                  <button className="styled-button">
                    <span
                      className="material-symbols-outlined"
                      onClick={() => handleRetriveProduct(item.id)}
                    >
                      undo
                    </span>
                  </button>
                  <button className="styled-button">
                    <span
                      className="material-symbols-outlined delete-button"
                      onClick={() => handleDeleteProduct(item.id)}
                    >
                      delete_forever
                    </span>
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No items found</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="footer-DataTable">
        <div className="per-page">
          <select value={itemsPerPage} onChange={handlePerPageChange}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
        </div>
        <Pagination className="pagination-custom">
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          <Pagination.Item disabled>
            Page {currentPage} of {totalPages}
          </Pagination.Item>
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
};
export default ProductRecycleBin;
