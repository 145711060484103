import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom"; // Import the zoom styles
import "./ProductGallery.css";
import { website } from "../utils/GlobalUtils";

import { FreeMode, Navigation, Thumbs, Zoom } from "swiper/modules";

export default function ProductGallery({ main_image, media }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [mainSwiper, setMainSwiper] = useState(null);
  const mediaUrls = media ? media.map((item) => `${website + item.url}`) : [];
  const images = [`${website + main_image}`, ...mediaUrls];

  // Function to toggle zoom and cursor style
  const toggleZoom = () => {
    if (mainSwiper) {
      mainSwiper.zoom.toggle(); // Toggle zoom
      const zoomed = mainSwiper.zoom.enabled && mainSwiper.zoom.scale !== 1;
      const zoomContainers = document.querySelectorAll('.swiper-zoom-container');
      zoomContainers.forEach(container => {
        if (zoomed) {
          container.classList.add('zoomed');
        } else {
          container.classList.remove('zoomed');
        }
      });
    }
  };

  return (
    <>
      <Swiper
        onSwiper={setMainSwiper}
        spaceBetween={10}
        thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
        zoom={true}
        modules={[FreeMode, Thumbs, Navigation, Zoom]}
        className="mySwiper2"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index} className="swiperProduct">
            <div className="swiper-zoom-container" onClick={toggleZoom}>
              <img src={image} alt={`Slide ${index + 1}`} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {images.length > 1 && (
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          {images.map((image, index) => (
            <SwiperSlide key={index} className="product-imageContainer-swiper">
              <img
                src={image}
                alt={`Thumbnail ${index + 1}`}
                className="product-image-swiper"
              />
            </SwiperSlide>
          ))}

          <button
            className="custom-next-button"
            onClick={() => mainSwiper && mainSwiper.slideNext()}
          >
            <span className="material-symbols-outlined">arrow_right_alt</span>
          </button>
          <button
            className="custom-prev-button"
            onClick={() => mainSwiper && mainSwiper.slidePrev()}
          >
            <span className="material-symbols-outlined">arrow_left_alt</span>
          </button>
        </Swiper>
      )}
    </>
  );
}