import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaHome, FaAddressCard, FaPhone, FaFacebook, FaInstagram } from 'react-icons/fa';
import "./Sidebar.css";
import { getCategorieshierarchy } from "../utils/CategoryUtil";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [categoryStack, setCategoryStack] = useState([]);
  const [categoryPathStack, setCategoryPathStack] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isTitle, setIsTitle] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getCategorieshierarchy(setCategories);
  }, []);

  const handleCategoryClick = (category,isTitle) => {
   
    if (!category) {
      navigate("/productList", {
        state: { categoryId: [] },
      });
      toggleSidebar();
      return;
    }

    const categoryPath = category.path.map(cat => cat.id);
   if(isTitle){
    navigate("/productList", {
      state: { categoryId: categoryPath },
    });
    toggleSidebar();
   }

    if (category.sub_categories && category.sub_categories.length > 0) {
     
      setCategoryStack([...categoryStack, category]);
      setCategoryPathStack([...categoryPathStack, ...categoryPath]);
    } else {
      
      navigate("/productList", {
        state: { categoryId: categoryPath },
      });
      toggleSidebar();
    }
  };

  const renderCategories = (categories) => {
    return categories.map((category) => (
      <li key={category.id} className="d-flex flex-column item-sidebar">
        <div
          className="d-flex justify-content-between"
          onClick={() => handleCategoryClick(category)}
        >
          {category.sub_categories && category.sub_categories.length > 0 ? (
            <span>{category.name}</span>
          ) : (
            <Link to={`/${category.name.replace(/\s+/g, "")}`}>
              {category.name}
            </Link>
          )}
          {category.sub_categories && category.sub_categories.length > 0 && (
            <span
              className={`material-symbols-outlined arrow-icon ${isVisible ? "visible" : ""}`}
            >
              arrow_forward_ios
            </span>
          )}
        </div>
      </li>
    ));
  };

  const renderSubCategories = (category) => {
    return (
      <>
        <div className="mb-3 nav-subcategoery">
          <span
            className="material-symbols-outlined arrow-back"
            onClick={() => {
              setCategoryStack(categoryStack.slice(0, -1));
              setCategoryPathStack(categoryPathStack.slice(0, -1 * category.path.length));
            }}
          >
            keyboard_backspace
          </span>
          <span className="nav-subcategory-name" onClick={() => handleCategoryClick(category,true)}>{category.name}</span>
        </div>
        <hr />
        {category.sub_categories.map((subCategory) => (
          <li
            key={subCategory.id}
            className="d-flex flex-column item-sidebar"
            onClick={() => handleCategoryClick(subCategory)}
          >
            {subCategory.sub_categories.length > 0 ? (
              <span>{subCategory.name}</span>
            ) : (
              <Link to={`/${subCategory.name.replace(/\s+/g, "")}`}>
                {subCategory.name}
              </Link>
            )}
            {subCategory.sub_categories.length > 0 && (
              <span
                className={`material-symbols-outlined arrow-icon ${isVisible ? "visible" : ""}`}
              >
                arrow_forward_ios
              </span>
            )}
          </li>
        ))}
      </>
    );
  };

  return (
    <>
      <div className={`sidebar-jf ${isOpen ? "open" : ""}`}>
        <button className="close-button" onClick={toggleSidebar}>
          <span className="material-symbols-outlined">close</span>
        </button>
        <ul className="sidebar-menu-items-jf">
          {categoryStack.length > 0 ? (
            renderSubCategories(categoryStack[categoryStack.length - 1])
          ) : (
            <>
              <li className="d-flex justify-content-between item-sidebar item-sidebar--main">
                <div className="Side__Link--container" onClick={toggleSidebar}>
                  <FaHome size={24} />
                  <Link to="/">Home</Link>
                </div>
              </li>
              <li className="d-flex justify-content-between item-sidebar item-sidebar--main">
                <div className="Side__Link--container" onClick={toggleSidebar}>
                  <FaAddressCard size={24} />
                  <Link to="/AboutUs">About Us</Link>
                </div>
              </li>
              <li className="d-flex justify-content-between item-sidebar item-sidebar--main">
                <div className="Side__Link--container" onClick={toggleSidebar}>
                  <FaPhone size={22} />
                  <Link to="/ContactUs">Contact Us</Link>
                </div>
              </li>
              <hr />
              <h5 className="sidebar__title">Categories</h5>
              <li className="d-flex justify-content-between item-sidebar">
                <div
                  className="main__category"
                  onClick={() => {
                    handleCategoryClick();
                  }}
                >
                  All Products
                </div>
              </li>
              {renderCategories(categories)}
              <hr />
              <h5 className="sidebar__title">Our Socials Cotton</h5>
              <div className="nav-icons">
                <a href="https://www.facebook.com/profile.php?id=61562843073149&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                  <FaFacebook size={30} style={{ color: '#4267B2' }} />
                </a>
                <a href="https://www.instagram.com/linecottonlb/" target="_blank" rel="noopener noreferrer">
                  <FaInstagram size={32} style={{ color: '#E1306C' }} />
                </a>
              </div>
              <h5 className="sidebar__title mt-4" >Our Socials Plast</h5>
              <div className="nav-icons">
                <a href="https://www.facebook.com/profile.php?id=61562679975110&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                  <FaFacebook size={30} style={{ color: '#4267B2' }} />
                </a>
                <a href="https://www.instagram.com/lineplastlb/" target="_blank" rel="noopener noreferrer">
                  <FaInstagram size={32} style={{ color: '#E1306C' }} />
                </a>
              </div>
            </>
          )}
        </ul>
        <div></div>
      </div>
      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            opacity: isOpen,
            transition: "opacity 0.3s ease",
            zIndex: 999,
          }}
          onClick={toggleSidebar}
        />
      )}
    </>
  );
};

export default Sidebar;
