import React, { useContext, useEffect, useRef } from "react";
import { SearchContext } from "./SearchContext";
import { useNavigate } from "react-router-dom";

const SearchBar = ({ isSearchVisible }) => {
  const { searchValue, setSearchValue } = useContext(SearchContext);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      navigate(`/productList?search=${encodeURIComponent(searchValue)}`, {
        state: { categoryId: [] },
      });
    }
  };

  useEffect(() => {
   
    if (isSearchVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearchVisible]);

  return (
    <div className={`search-bar-container ${isSearchVisible ? "show" : ""}`}>
      <input
        ref={inputRef}
        type="text"
        className="search-input-jf"
        placeholder="Search..."
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default SearchBar;
