import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container, Row, Col, Form, Breadcrumb } from "react-bootstrap";
import { getAllProducts } from "./utils/ProductUtil";
import ProductsCard from "./products/ProductsCard";
import { SearchContext } from "./header/SearchContext";
import { getCategorieshierarchy } from "./utils/CategoryUtil";
import "./ProductsList.css";
import { useLocation } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Spinner from "./Spinner";
import ProductsListSkeleton from "./ProductSkeleton";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./ProductSkeleton.css"; // Import the CSS file
const ProductsList = () => {
  const { searchValue, setSearchValue } = useContext(SearchContext);
  const location = useLocation();
  const { categoryId: initialCategoryId } = location.state ;
    const [categoryId, setCategoryId] = useState(initialCategoryId );
  const [loading, setLoading] = useState(true);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(100000);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsData, setProductsData] = useState({ data: [], total: 0 });
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [currentItems, setCurrentItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedInventoryId, setSelectedInventoryId] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [categoriesData, setCategoriesData] = useState({ data: [], total: 0 });
  const [selectedCategories, setSelectedCategories] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState([]);
  const [isCategoryIdSet, setIsCategoryIdSet] = useState(false);
  const [isProductsLoading, setProductsLoading] = useState(false);

  const navigate = useNavigate();

  const defaultCategoryIds = initialCategoryId;



  const placeholderItems = Array.from({ length: 9 }, (_, index) => ({
    id: `placeholder-${index}`,
    name: "loading",
    main_image: "/storage/images/1720729759_gradient.png",
    price: "",
  }));

  useEffect(() => {
    if (categoryId > 0) {
      setSelectedCategories({ [categoryId]: true });
      setSelectedCategoryId(categoryId);
      handleToggleExpand(categoryId);
      setIsCategoryIdSet(true);
    } else {
      const expanded = {};
      const selected = {};
      defaultCategoryIds.forEach((id) => {
        expanded[id] = true;
        selected[id] = true;
      });
      setExpandedCategories(expanded);
      setSelectedCategories(selected);
      setSelectedCategoryId(defaultCategoryIds);
      setIsCategoryIdSet(true);
    }
  }, [initialCategoryId]);

  useEffect(() => {
    const { categoryId: newCategoryId } = location.state || {};
    if (newCategoryId && newCategoryId !== categoryId) {
      setCategoryId(newCategoryId);
      setCurrentPage(1);
    }
  }, [initialCategoryId]);

  const currentCategoryId =
    selectedCategoryId.length > 0
      ? selectedCategoryId
      : categoryId
      ? categoryId
      : [];

  useEffect(() => {
    if (
      Object.keys(selectedCategories).length === 0 &&
      categoryId.length !== 0
    ) {
      setCategoryId([]);
    }
  }, [categoryId, selectedCategories]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const fetchData = async () => {
    setProductsLoading(true);
    await getAllProducts(
      currentPage,
      itemsPerPage,
      searchValue,
      minPrice,
      maxPrice,
      currentCategoryId,
      selectedInventoryId,
      setProductsData,
      setCurrentItems,
      setLoading
    );
    setProductsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    itemsPerPage,
    searchValue,
    minPrice,
    maxPrice,
    selectedCategoryId,
    selectedInventoryId,
  ]);

  const fetchSubData = () => {
    getCategorieshierarchy(setCategories);
  };

  useEffect(() => {
    fetchSubData();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get("search");
    if (searchQuery) {
      setSearchValue(decodeURIComponent(searchQuery));
    }
  }, [location, setSearchValue]);

  const updateCategories = (categories, parentId, newSubCategory) => {
    return categories.map((category) => {
      if (category.id === parentId) {
        return {
          ...category,
          sub_categories: [...(category.sub_categories || []), newSubCategory],
        };
      } else if (category.sub_categories) {
        return {
          ...category,
          sub_categories: updateCategories(
            category.sub_categories,
            parentId,
            newSubCategory
          ),
        };
      } else {
        return category;
      }
    });
  };

  const handleToggleExpand = (id) => {
    setExpandedCategories((prevExpandedCategories) => ({
      ...prevExpandedCategories,
      [id]: true,
    }));
  };

  const getCategoryDepth = (categories, id, depth = 0) => {
    for (const category of categories) {
      if (category.id === id) {
        return depth;
      }
      if (category.sub_categories) {
        const subDepth = getCategoryDepth(
          category.sub_categories,
          id,
          depth + 1
        );
        if (subDepth !== -1) {
          return subDepth;
        }
      }
    }
    return -1;
  };

  const handleToggleSelect = (id) => {
    setCurrentPage(1);
    const depth = getCategoryDepth(categories, id);
    const parentCategory = getParentCategory(categories, id);
    if (parentCategory) {
      setSelectedCategories((prevSelectedCategories) => {
        const newSelectedCategories = { ...prevSelectedCategories };
        if (newSelectedCategories[id]) {
          delete newSelectedCategories[id];
        } else {
          for (const key in newSelectedCategories) {
            if (
              getParentCategory(categories, parseInt(key))?.id ===
              parentCategory.id
            ) {
              delete newSelectedCategories[key];
            }
          }
          newSelectedCategories[id] = true;
        }
        return newSelectedCategories;
      });

      setSelectedCategoryId((prevSelectedCategoryId) =>
        prevSelectedCategoryId.filter((categoryId) => categoryId !== id)
      );
    } else {
      setSelectedCategories((prevSelectedCategories) => {
        const newSelectedCategories = {};
        if (!prevSelectedCategories[id]) {
          newSelectedCategories[id] = true;
        }
        return newSelectedCategories;
      });
    }

    setSelectedCategoryId((prevSelectedCategoryId) => {
      if (!prevSelectedCategoryId.includes(id)) {
        const newSelectedCategoryId = prevSelectedCategoryId.filter(
          (categoryId) => getCategoryDepth(categories, categoryId) !== depth
        );

        return [...newSelectedCategoryId, id];
      }
      return prevSelectedCategoryId;
    });
  };

  useEffect(() => {
    const newIds = Object.keys(selectedCategories).map((key) =>
      parseInt(key, 10)
    );
    setSelectedCategoryId(newIds);
  }, [selectedCategories]);

  const getParentCategory = (categories, childId) => {
    for (const category of categories) {
      if (category.sub_categories) {
        for (const subCategory of category.sub_categories) {
          if (subCategory.id === childId) {
            return category;
          } else {
            const parent = getParentCategory(category.sub_categories, childId);
            if (parent) {
              return parent;
            }
          }
        }
      }
    }
    return null;
  };

  const renderCategories = (categories, depth = 0) => {
    const getClassForDepth = (depth) => {
      switch (depth) {
        case 1:
          return "subcategory-item";
        case 2:
          return "sub-subcategory-item";
        default:
          return "";
      }
    };

    return (
      <dl>
        {categories.map((category) => {
          const isParentSelected = selectedCategories[category.id];
          const parentCategory = getParentCategory(categories, category.id);
          const isChildOfSelectedParent =
            parentCategory && selectedCategories[parentCategory.id];

          return (
            <div key={category.id}>
              <div
                className={`category-item-filter ${getClassForDepth(depth)} ${
                  selectedCategories[category.id] ? "selected" : ""
                }`}
                onClick={() => {
                  handleToggleExpand(category.id);
                  handleToggleSelect(category.id);
                }}
              >
                <div className="d-flex">
                  <input
                    type="checkbox"
                    checked={selectedCategories[category.id] || false}
                    onChange={() => handleToggleSelect(category.id)}
                    className="checkbox-category"
                  />
                  <span>{category.name}</span>
                </div>
              </div>

              {(isParentSelected || isChildOfSelectedParent) &&
                expandedCategories[category.id] &&
                category.sub_categories &&
                category.sub_categories.length > 0 && (
                  <dl className="expanded">
                    {renderCategories(category.sub_categories, depth + 1)}
                  </dl>
                )}
            </div>
          );
        })}
      </dl>
    );
  };

  const handleRemove = (id) => {
    setSelectedCategories((prevSelectedCategories) => {
      const { [id]: _, ...remainingCategories } = prevSelectedCategories;
      return remainingCategories;
    });

    handleToggleExpand(id);
  };

  const getSelectedCategoryNames = (categories, selectedIds) => {
    const names = [];
    const traverse = (categories) => {
      for (const category of categories) {
        if (selectedIds[category.id]) {
          names.push({ id: category.id, name: category.name });
        }
        if (category.sub_categories) {
          traverse(category.sub_categories);
        }
      }
    };
    traverse(categories);
    return names;
  };

  const selectedCategoryNames = getSelectedCategoryNames(
    categories,
    selectedCategories
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const renderSelectedCategoriesWithParents = () => {
    const selectedCategoriesWithParents = selectedCategoryNames.map(
      (category) => {
        const parentCategory = getParentCategory(categories, category.id);
        return {
          ...category,
          parent: parentCategory ? parentCategory.name : null,
        };
      }
    );

    return (
      <div className="all-chips-container-ms">
        {searchValue !== "" && (
          <div className="chip search-chip">
            <span className="material-symbols-outlined left-icon">search</span>
            {searchValue}
            <span
              className="material-symbols-outlined btn-close-chip"
              onClick={() => {
                setSearchValue("");
                document.querySelector(".search-input-jf").value = "";
              }}
            >
              close
            </span>
          </div>
        )}
        {selectedCategoriesWithParents.map((category) => (
          <div key={category.id} className="chip">
            {category.name}
            <span
              className="material-symbols-outlined btn-close-chip"
              onClick={() => handleRemove(category.id)}
            >
              close
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <>
        <div className="product-list-full-container-ms">
          <Col>
            <h4 className="breadcrumb-style mt-3">
              <Link to={"/"}>Home</Link> {/* /{" "} */}
              {/* <Link>{}</Link> /{" "}
              <span className="last-breadcrumb-item">{currentItems.name}</span> */}
            </h4>
          </Col>
        </div>

        <div className="product-list-full-container-ms">
          <div className="chips-container-ms">
            {(selectedCategoryNames.length > 0 || searchValue !== "") && (
              <div>{renderSelectedCategoriesWithParents()}</div>
            )}
          </div>
        </div>

        <div className="product-list-full-container-ms">
          <div className="flex-grow-ms">
            <div className="product-list-container-ms">
              
              {isProductsLoading ? (
                <ProductsListSkeleton />
              ) : currentItems.length > 0 ? (
                currentItems.map((item) => (
                  <div key={item.id} className="product-list-item-ms">
                    <ProductsCard
                      id={item.id}
                      name={item.name}
                      main_image={item.main_image}
                      price={item.price}
                    />
                  </div>
                ))
              ) : (
                <div className="no-products-found-ms">
                  <h5>No products found</h5>
                </div>
              )}
            </div>

            {currentItems.length > 0 && (
              <div className="paginator-ms">
                <Pagination
                  count={Math.ceil(productsData.total / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{
                        previous: ArrowBackIcon,
                        next: ArrowForwardIcon,
                      }}
                      {...item}
                    />
                  )}
                />
              </div>
            )}
          </div>

          <div className="product-list-filter-container-ms">
            <h4>Filters</h4>
            <div>{renderCategories(categories)}</div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ProductsList;
