import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-bootstrap/Pagination";
import "../AdminDataTable.css";
import { getContacts } from "../../../utils/ContactUtil";
const ContactUsDataTable = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [itemsPerPage, setItemsPerPage] = useState(5);

  const [currentItems, setCurrentItems] = useState([]);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const handlePerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };
  const fetchData = () => {
    getContacts(setCurrentItems);
  };

  useEffect(() => {
    fetchData();
  }, []);

 
  const totalItems = currentItems.length;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="container-DataTable card  mt-4">
      <div className="row header-DataTable">
        <div className="col-md-3  col-6 order-1 ">
          <h1>ContactUs List</h1>
        </div>
        <div className="col-md-9 justify-content-center d-flex  order-3 order-md-2">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => handleSearchChange(e.target.value)}
            className="search-input"
          />
        </div>

        {isDropdownVisible && (
          <div className="dropdown-Filter">
            <div className="filter"></div>
          </div>
        )}
      </div>
      <table className="custom-table">
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Email</th>
            <th>Message</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {currentItems && currentItems.length > 0 ? (
            currentItems.map((item) => (
              <tr key={item.id}>
                <td>
                  {item.first_name} {item.last_name}
                </td>
                <td>{item.email}</td>
                <td>{item.message}</td>
                <td>{item.created_at.split("T")[0]}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No items found</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="footer-DataTable">
        <div className="per-page">
          <select value={itemsPerPage} onChange={handlePerPageChange}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
        </div>
        <Pagination className="pagination-custom">
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          <Pagination.Item disabled>
            Page {currentPage} of {totalPages}
          </Pagination.Item>
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default ContactUsDataTable;
