import { getAuth } from "./GlobalUtils";

export const getProductUser = async (currentPage, itemsPerPage, searchTerm) => {
  try {
    const response = await getAuth(`/user-products/${itemsPerPage}?page=${currentPage}&user_id=${209}&product_id=${23}&search=${searchTerm}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching searchProducts:", error);
    throw error;
  }
};