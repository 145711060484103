import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './ProductCarsoul.css';
import { Keyboard, Pagination, Navigation } from 'swiper/modules';
import ProductsCard from './ProductsCard';

export default function ProductCarsoul({ categoryId, currentItems, itemsPerPage }) {
  const [mainSwiper, setMainSwiper] = useState(null);
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);
  const [slidesPerView, setSlidesPerView] = useState(1);

  useEffect(() => {
    const updateButtonsVisibility = () => {
      if (mainSwiper && mainSwiper.slides && mainSwiper.params) {
        setShowPrevButton(
          mainSwiper.activeIndex !== 0 && window.innerWidth < 1274
        );
        setShowNextButton(
          mainSwiper.activeIndex !== mainSwiper.slides.length - mainSwiper.params.slidesPerView && window.innerWidth < 1274
        );
      }
    };

    updateButtonsVisibility();
  }, [mainSwiper, slidesPerView]);

  const handleSlideChange = (swiper) => {
    if (swiper && swiper.slides && swiper.params) {
      setShowPrevButton(
        swiper.activeIndex !== 0 && window.innerWidth < 1274
      );
      setShowNextButton(
        swiper.activeIndex !== swiper.slides.length - swiper.params.slidesPerView && window.innerWidth < 1274
      );
    }
  };

  const calculateSlidesPerView = (slidesPerView) => {
    return itemsPerPage > slidesPerView ? slidesPerView : itemsPerPage;
  };

  const breakpoints = {
    700: {
      slidesPerView: calculateSlidesPerView(3),
      spaceBetween: 0,
      slidesPerGroup: calculateSlidesPerView(3),
    },
    1024: {
      slidesPerView: calculateSlidesPerView(4),
      spaceBetween: 0,
      slidesPerGroup: calculateSlidesPerView(4),
    },
    1274: {
      slidesPerView: calculateSlidesPerView(5),
      spaceBetween: 0,
      slidesPerGroup: calculateSlidesPerView(5),
    },
  };

  useEffect(() => {
    const determineSlidesPerView = () => {
      const width = window.innerWidth;
      if (width >= 1274) {
        setSlidesPerView(calculateSlidesPerView(5));
      } else if (width >= 1024) {
        setSlidesPerView(calculateSlidesPerView(4));
      } else if (width >= 700) {
        setSlidesPerView(calculateSlidesPerView(3));
      } else {
        setSlidesPerView(1);
      }
    };

    determineSlidesPerView();
    window.addEventListener('resize', determineSlidesPerView);

    return () => {
      window.removeEventListener('resize', determineSlidesPerView);
    };
  }, [itemsPerPage]);

  return (
    <>
      {currentItems.length > 0 && (
        <Swiper
          onSwiper={setMainSwiper}
          onSlideChange={handleSlideChange}
          keyboard={{ enabled: true }}
          pagination={{ clickable: true }}
          breakpoints={breakpoints}
          modules={[Keyboard, Pagination, Navigation]}
          id='swiper-cardcasoul'
          className='carousel-of-products'
        >
          {currentItems.map((item) => (
            <SwiperSlide key={item.id}>
              <ProductsCard
                id={item.id}
                name={item.name}
                main_image={item.main_image}
                price={item.price}
                category={item.category}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      {currentItems.length > slidesPerView && itemsPerPage > 4 && window.innerWidth < 1274 && (
        <>
          {showNextButton && (
            <button
              className="productCarsoul-next-button"
              onClick={() => mainSwiper && mainSwiper.slideNext()}
            >
              <span className="material-symbols-outlined">
                arrow_right
              </span>
            </button>
          )}
          {showPrevButton && (
            <button
              className="productCarsoul-prev-button"
              onClick={() => mainSwiper && mainSwiper.slidePrev()}
            >
              <span className="material-symbols-outlined">
                arrow_left
              </span>
            </button>
          )}
        </>
      )}
    </>
  );
}
