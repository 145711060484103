import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/M dagher Logo.svg";
import burgerMenu from "../../assets/images/burger-menu.svg";
import "./Navbar.css";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";
import NavigationBarBottom from "./NavigationBarBottom";

const NavigationBar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [navbarVisible, setNavbarVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const toggleSearchBar = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const controlNavbar = () => {
    const currentScrollY = window.scrollY;
    // Check the direction of the scroll
    if (currentScrollY < lastScrollY) {
      setNavbarVisible(true); // Show navbar when scrolling up
    } else if (currentScrollY > lastScrollY) {
      setNavbarVisible(false); // Hide navbar when scrolling down
    }
    // Update the last scroll position
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    const handleScroll = () => {
      controlNavbar();
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <>
      <nav
        className={`navbar-jf ${navbarVisible ? "" : "hide"} 
        `}
      >
        <div className="left-content-jf">
          <button className="navbar-menu-button-jf" onClick={toggleSidebar}>
            <img
              src={burgerMenu}
              className="burger-menu-jf"
              alt="nav-burger-menu"
            />
          </button>
          <button className="navbar-menu-button-jf" onClick={toggleSearchBar}>
            <span className="material-symbols-outlined search-icon-jf">
              search
            </span>
          </button>
        </div>
        <div className="center-content-jf">
          <img
            src={logo}
            className="nav-logo-jf"
            alt="logo"
            onClick={() => navigate(`/`)}
          />
        </div>
        <div className="right-content-jf">
          <Link to="tel:+1234567890" className="call-us-link-jf">
            <span className="material-symbols-outlined">call</span>
            <span className="is-mobile">Contact Us</span>
          </Link>
        </div>
        <div className={`search-bar ${isSearchVisible ? "visible" : "hidden"}`}>
          <SearchBar
            isSearchVisible={isSearchVisible}
          />
        </div>{" "}
       
      </nav>

      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />

      <div className={`navbar-invisible ${isSearchVisible ? "open-more" : ""} `}></div>

      
     

    </>
  );
};

export default NavigationBar;
