import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { updateCategory } from '../../../utils/CategoryUtil';
import { website } from "../../../utils/GlobalUtils";

function EditCategoryPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = location.state || {}; // Destructure data from state

  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(website + (data?.url || ""));
  const [formData, setFormData] = useState({
    name: data?.name || "",
    is_main_category: data?.is_main_category || "0",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name.trim()) return;
    try {
      const formDataCategory = new FormData();
      formDataCategory.append('name', formData.name);
      formDataCategory.append('is_main_category', formData.is_main_category);
      if (file) {
        formDataCategory.append('url', file);
      }

      const newCategory = await updateCategory(data.id, formDataCategory);
      navigate("/Admin/categories"); 
    } catch (error) {
      console.error('Error updating Category:', error);
    }
  };

  const handleBackToList = () => {
    navigate("/Admin/categories"); 
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (data) {
      setFormData({
        name: data.name || "",
        is_main_category: data.is_main_category.toString() || "0",
      });
    }
  }, [data]);

  const handleFileChange = (e) => {
    const { files: inputFiles } = e.target;
    const file = inputFiles[0];
    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpg", "png", "jpeg"].includes(extension)) {
        setFile(file);
        setPreviewUrl(URL.createObjectURL(file)); // Update preview URL
      } else {
        console.log("Invalid file type.");
      }
    }
  };

  return (
    <Container >
      <div className="card-header bg-secondary bg-gradient ml-0 py-3 mb-4">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="text-white">Update Category</h1>
          </div>
        </div>
      </div>
      <Form className="card-components" onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col sm={12} className="mb-5 w-100">
            <Form.Group controlId="CategoryName">
              <Form.Label className="title-text">Category Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Category Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group controlId="CategoryIsMain">
              <Form.Label className="title-text">Is Main Category</Form.Label>
              <div>
                <Form.Check
                  inline
                  label="Yes"
                  type="radio"
                  id="isMainYes"
                  name="is_main_category"
                  value="1"
                  checked={formData.is_main_category === "1"}
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  label="No"
                  type="radio"
                  id="isMainNo"
                  name="is_main_category"
                  value="0"
                  checked={formData.is_main_category === "0"}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group>
              <Form.Label className="title-text">Category Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleFileChange}
                onInvalid={() =>
                  alert("Please select files with extensions .jpg, .png, or .jpeg")
                }
                accept=".jpg, .jpeg, .png"
                id="CategoryImage"
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            {previewUrl && (
              <div className="image-preview mt-2"
              style={{
                position: "relative",
                width: "200px",
              }}>
                <img src={previewUrl} alt="Category Preview" className="img-fluid" />
              </div>
            )}
          </Col>
        </Row>
        <div className="d-flex justify-content-between">
          <Button
            className="Styled-Button Back-Button"
            variant="secondary"
            onClick={handleBackToList}
          >
            Go Back
          </Button>
          <Button
            className="Styled-Button Create-Button"
            variant="primary"
            type="submit"
          >
            Update Category
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default EditCategoryPage;
