import React, { useState,useEffect } from "react";
import "./ContactUs.css";
import { AddContact } from "../utils/ContactUtil";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isMessageSending, setIsMessageSending] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsMessageSending(true);

    const [first_name, last_name] = splitName(name);

    try {
      await AddContact(first_name, last_name, email, message);
      alert("Message sent successfully!");
      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send message. Please try again.");
    } finally {
      setIsMessageSending(false);
    }
  };

  const splitName = (name) => {
    const nameParts = name.trim().split(" ");
    if (nameParts.length === 1) {
      return [nameParts[0], ""];
    }
    return [nameParts[0], nameParts.slice(1).join(" ")];
  };

  return (
    <div>
      <div className="page-wrapper section-space--inner--120">
        <div className="conact-section">
          <div className="container">
            <div className="row section-space--bottom--50">
              <div className="col">
                <div className="contact-map">
                  <iframe
                    title="google-map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13253.841072465746!2d35.5153726!3d33.8520272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f177c124d6349%3A0xaeb1ff084d2e9728!2sHaret%20Hreik!5e0!3m2!1sen!2slb!4v1720791672272!5m2!1sen!2slb&zoom=15"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-12">
                <div className="contact-information">
                  <h3>Contact Information</h3>
                  <ul>
                    <li>
                      <span className="icon">
                        <span className="material-icons-outlined">
                          location_on
                        </span>
                      </span>
                      <span className="text">
                        Lebanon, Haret Hreik
                        <br />
                        Abbass Moussawi Street
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <span className="material-icons-outlined">phone</span>
                      </span>
                      <span className="text">
                        Customer Service: <a className="contact__link" href="tel:+96103790205">+961 03 790 205</a>
                        Management:<a className="contact__link" href="tel:+96181818899">+961 81 818 899</a>
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <span className="material-icons-outlined">email</span>
                      </span>
                      <span className="text">
                        <a className="contact__link" target="_blank" href="mailto:admin@mdagherest.com">
                        contact@mdagherest.com
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 col-12">
                <div className="contact-form">
                  <h3>Leave Your Message</h3>
                  <form id="contact-form" onSubmit={handleSubmit}>
                    <div className="row row-10">
                      <div className="col-md-6 col-12 section-space--bottom--20">
                        <input
                          name="con_name"
                          type="text"
                          placeholder="Your Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 col-12 section-space--bottom--20">
                        <input
                          name="con_email"
                          type="email"
                          placeholder="Your Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="col-12">
                        <textarea
                          name="con_message"
                          placeholder="Your Message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </div>
                      <div className="col-12">
                        <button type="submit" disabled={isMessageSending}>
                          {isMessageSending ? "Sending..." : "Send Message"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
